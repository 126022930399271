/*
xShop - ecommerce and multipurpose HTML template
v1.0
Author : WRTeam
*/

/*-----------------------------------------------------
[TABLE OF CONTENTS]

- Firebase UI CSS
- Button Styling
- Input Styling
- Logo
- Header
- Logo
- Mega Menu
- Sidebar
- jquery-accordion-menu
- category-section
- Swiper1
- Swiper2
- category-swiper
- Global settings
- dollar signs
- Column headers
- Product entries
- Totals section
- Featured Section
- Footer
- Product-listing 
- Product-Page-widget
- listing-page-widget
- Dashboard Page
- form styling
- product grid
- vertical navigation
- timer
- list widget
- breadcrumb
- login-form
- faq's
- header varient
- footer varient
- newsletter
- invoice
- back-to-top
- my-account elements
- products with progress bar
- color-switcher
- mobile illustrator
- pagination
- blog
- element styling
- cart
- checkout
- contact us
- product grid view
- product details
- media query
--------------* * * * * * *-----------------------------
--------------* * * * * * *----------------------------
*/

@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Poppins', sans-serif;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    --primary-color: #0e7dd1;
    --secondary-color: #e5a60b;
    --light-color: #ffffff;
    --border-color: #f1f1f1;
    --brdcolor-gray-300: rgba(0, 0, 0, 0.105);
}

html,
body {
    position: relative !important;
    height: 100% !important;
    scroll-behavior: smooth;
}

body {
    background: #f8f8f8ff !important;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: #000 !important;
    margin: 0 !important;
    padding: 0 !important;
}

ion-icon {
    font-size: 25px;
}

img.lazy {
    opacity: 0;
}

img:not(.initial) {
    transition: opacity 1s;
}

img.initial,
img.loaded,
img.error {
    opacity: 1;
}

img:not([src]) {
    visibility: hidden;
}

.sign-up-image img {
    max-width: 100%;
}

.reg-form {
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 6px;
}

.login-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* 1.Sidebar */

.sidebar-text h6 {
    font-size: 16px;
}

.sidebar-toggle {
    margin-left: -240px;
}

.sidebar {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: absolute;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 100;
}

.sidebar #leftside-navigation ul,
.sidebar #leftside-navigation ul ul {
    margin: -2px 0 0;
    padding: 0;
}

.sidebar #leftside-navigation ul li {
    list-style-type: none;
    border-bottom: 1px solid rgba(255, 255, 255, .05);
}

.sidebar #leftside-navigation ul li.active>a {
    color: var(--primary-color);
}

.sidebar #leftside-navigation ul li.active ul {
    display: block;
}

.sidebar #leftside-navigation ul li a {
    color: #000000;
    text-decoration: none;
    display: block;
    padding: 18px 0 18px 25px;
    font-size: 16px;
    outline: 0;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.sidebar #leftside-navigation ul li a span {
    display: inline-block;
}

.sidebar #leftside-navigation ul li a i {
    width: 35px;
}

.sidebar #leftside-navigation ul li a i .fa-angle-left,
.sidebar #leftside-navigation ul li a i .fa-angle-right {
    padding-top: 3px;
}

.sidebar #leftside-navigation ul ul {
    display: none;
}

.sidebar #leftside-navigation ul ul li {
    background: #e5e6e6;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;
    border-bottom: none;
}

.sidebar #leftside-navigation ul ul li a {
    font-size: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
    color: #000000;
}

/* Firebase UI CSS */

.firebaseui-container {
    max-width: 500px !important;
}

.mdl-button--raised.mdl-button--colored {
    background: rgb(14 125 209) !important;
    color: rgb(255, 255, 255) !important;
}

.mdl-shadow--2dp {
    box-shadow: none !important;
}

/* Button Styling */
/* Box colors */
.block {
    display: block;
    width: 100%;
    border: none;
    background-color: var(--primary-color);
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}

.block:hover {
    background-color: #ddd;
    color: black;
}

.link-buttons {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: .3px;
    font-weight: 600 !important;
    cursor: pointer;
    transition: color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow .25s ease, opacity .25s ease;
}

.btn-element {
    vertical-align: middle;
    display: inline-block;
}

.btn-element a {
    margin: 0 12px;
}

.extra-large {
    font-size: 16px !important;
    line-height: 22px;
}

.large {
    font-size: 14px !important;
    line-height: 20px;
}

.extra-small {
    font-size: 12px !important;
    line-height: 16px;
}

.small {
    font-size: 12px !important;
    line-height: 16px;
}

.default {
    font-size: 13px !important;
    line-height: 18px;
}

.link-btn {
    font-size: 1.5rem;
    border-bottom: 1px solid;
    color: black;
}

.btn-circle {
    border-radius: 24px;
}

.bg-3 {
    background: #37474f;
    color: #fff;
}

.content h2 {
    font-size: 1.5em;
}

.buttons {
    margin: 1em;
    padding: 1em 1em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    transition: 150ms ease;
}

.buttons-mini {
    display: block;
    margin: 1em;
    padding: 1em 1em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.buttons:focus {
    outline: none;
}

.buttons>span {
    vertical-align: middle;
}


.bg-3 .buttons {
    color: #fff;
    border-color: #fff;
}


.button--size-s {
    font-size: 14px;
}

.button--size-m {
    font-size: 16px;
}

.button--size-l {
    font-size: 18px;
}


.button--text-upper {
    letter-spacing: 2px;
    text-transform: uppercase;
}

.button--text-thin {
    font-weight: 300;
}

.button--text-medium {
    font-weight: 500;
}

.button--text-thick {
    font-weight: 600;
}

.button--round-s {
    border-radius: 5px;
}

.button--round-m {
    border-radius: 15px;
}

.button--round-l {
    border-radius: 40px;
}


.button--border-thin {
    border: 1px solid;
}

.button--border-medium {
    border: 2px solid;
}

.button--border-thick {
    border: 3px solid;
}



.button.button--btn2 {
    background: #37474f;
    color: #fff;
    -webkit-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;
}

.button--btn2.button--inverted,
.button--btn2.button--inverted--style1,
.button--btn2.button--inverted--style2 {
    background: #fff;
    color: #37474f;
}

.button--btn2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #3f51b5;
    z-index: -1;
    border-radius: inherit;
    opacity: 0;
    -webkit-transform: scale3d(0.6, 0.6, 1);
    transform: scale3d(0.6, 0.6, 1);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button--btn2.button--inverted::before {
    border-color: cadetblue;
}

.button--btn2.button--inverted--style1::before {
    border-color: bisque;
}

.button--btn2.button--inverted--style1::before {
    border-color: bisque;
}

.button--btn2.button--inverted--style2::before {
    border-color: var(--primary-color);
}

.button--btn2:hover {
    background-color: #fff;
    color: #3f51b5;
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.button--btn2.button--inverted:hover {
    background-color: #37474f;
    color: cadetblue;
}

.button--btn2.button--inverted--style1:hover {
    background-color: #37474f;
    color: bisque;
}

.button--btn2.button--inverted--style2:hover {
    background-color: #37474f;
    color: var(--primary-color);
}

.button--btn2:hover::before {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.button--btn-4 {
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, .15);
    border: none;
    border-radius: 3px;
}

.gradient-horizon {
    background-image: linear-gradient(90deg, #833ab4 0, #fd1d1d 50%, #fcb045 100%) !important;
}

.gradient-horizon:hover {
    background-image: linear-gradient(90deg, #fcb045 100%, #fd1d1d 50%, #833ab4 0) !important;
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.gradient-green-blue {
    background-image: linear-gradient(to right, #43cea2, #185a9d) !important;
}

.gradient-green-blue:hover {
    background-image: linear-gradient(to right, #185a9d, #43cea2) !important;
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.gradient-yellow-pink {
    background: linear-gradient(to right, #3a1c71, #d76d77, #ffaf7b);
}

.gradient-yellow-pink:hover {
    background: linear-gradient(to right, #ffaf7b, #d76d77, #3a1c71);
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.gradient {
    background: linear-gradient(to right, #8e0e00, #1f1c18);
}

.gradient:hover {
    background: linear-gradient(to right, #1f1c18, #8e0e00);
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.gradient-pink-green {
    background: linear-gradient(to right, #fe8c00, #f83600);
}

.gradient-pink-green:hover {
    background: linear-gradient(to right, #f83600, #fe8c00);
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.btn-5 {
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, .15);
    border: none;
}

.red-button {
    background-color: #c02942;
}

.teal-button {
    background-color: #53777a;
}

.light-button {
    background-color: #ecd078;
}

.green-button {
    background-color: #59ba41;
}

.black-button {
    background-color: #161616;
}

.primary-button {
    background-color: #3498db;
}

.btn-6-1 {
    border: 2px solid #c02942 !important;
    color: #c02942 !important;
}

.btn-6-1:hover {
    background-color: #c02942;
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.btn-6-2 {
    border: 2px solid #53777a !important;
    color: #53777a !important;
}

.btn-6-2:hover {
    background-color: #53777a;
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.btn-6-3 {
    border: 2px solid #ecd078 !important;
    color: #ecd078 !important;
}

.btn-6-3:hover {
    background-color: #ecd078;
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.btn-6-4 {
    border: 2px solid #59ba41 !important;
    color: #59ba41 !important;
}

.btn-6-4:hover {
    background-color: #59ba41;
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.btn-6-5 {
    border: 2px solid #161616 !important;
    color: #161616 !important;
}

.btn-6-5:hover {
    background-color: #161616;
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.btn-6-6 {
    border: 2px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.btn-6-6:hover {
    background-color: var(--primary-color);
    color: #fff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.title-border {
    border-top: 1px solid lightgray;
}

.red-button:hover,
.teal-button:hover,
.light-button:hover,
.green-button:hover,
.black-button:hover,
.primary-button:hover {
    opacity: .9;
    transform: translateY(-1px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05)
}

.red-button,
.teal-button,
.light-button,
.green-button,
.black-button,
.primary-button {
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    transition: 150ms;
    cursor: pointer;
    transition: 150ms ease;
}

.gradient-horizon,
.gradient-green-blue,
.gradient-yellow-pink,
.gradient,
.gradient-pink-green {
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}

button.block {
    transition: 150ms;
    cursor: pointer;
}

button.block:focus {
    outline: none;
}

.clr-facebbok {
    background-color: #3b5998 !important;
    color: #fff;
}

.clr-facebbok:hover,
.clr-twitter:hover,
.clr-insta:hover,
.clr-codepan:hover,
.clr-github:hover,
.clr-search:hover,
.clr-view:hover,
.clr-cart:hover,
.clr-youtube:hover {
    transform: translateY(-2px);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.clr-codepan:hover,
.clr-github:hover {
    background-color: #000 !important;
}

.clr-twitter {
    background-color: #1da1f2 !important;
}

.clr-insta {
    background-color: #405de6 !important;
}

.clr-search {
    background-color: var(--primary-color) !important;
}

.clr-view {
    background-color: var(--primary-color) !important;
}

.clr-cart {
    background-color: #7fd27f !important;
}

.clr-youtube {
    background-color: #ff0000 !important;
}

.btn-6-1,
.btn-6-2,
.btn-6-3,
.btn-6-4,
.btn-6-5 {
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}

.btn-dark {
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: #fff;
    background: none;
    padding: 15px 30px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

.btn-dark:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    margin: 2px 2px 2px 2px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.button-rounded {
    border-radius: 20px;
}

.button-primary {
    color: #fff;
    background-color: #3787de !important;
    border-color: #007bff !important;
}

.button-secondary {
    color: #fff;
    background-color: #5a6d90 !important;
    border-color: #5a6d90 !important;
}

.button-success {
    color: #fff;
    background-color: #2eca8b !important;
    border-color: #2eca8b !important;
}

.button-danger {
    color: #fff;
    background-color: #e43f52 !important;
    border-color: #e43f52 !important;
}

.button-warning {
    color: #fff;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.button-primary-outline {
    color: #3787de !important;
    border-color: #007bff !important;
}

.button-secondary-outline {
    color: #5a6d90 !important;
    border-color: #5a6d90 !important;
}

.button-success-outline {
    color: #2eca8b !important;
    border-color: #2eca8b !important;
}

.button-danger-outline {
    color: #e43f52 !important;
    border-color: #e43f52 !important;
}

.button-warning-outline {
    color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.button-primary:hover,
.button-primary:focus,
.button-primary-outline:hover,
.button-primary-outline:focus {
    color: #fff !important;
    background-color: #4c92de !important;
    border-color: #4c92de !important;
    outline: 0;
}

.button-secondary:hover,
.button-secondary:focus,
.button-secondary-outline:hover,
.button-secondary-outline:focus {
    color: #fff !important;
    background-color: #707d96 !important;
    border-color: #707d96 !important;
    outline: 0;
}

.button-success:hover,
.button-success:focus,
.button-success-outline:hover,
.button-success-outline:focus {
    color: #fff !important;
    background-color: #30d290 !important;
    border-color: #30d290 !important;
    outline: 0;
}

.button-danger:hover,
.button-danger:focus,
.button-danger-outline:hover,
.button-danger-outline:focus {
    color: #fff !important;
    background-color: #e95d6d !important;
    border-color: #e95d6d !important;
    outline: 0;
}

.button-warning:hover,
.button-warning:focus,
.button-warning-outline:hover,
.button-warning-outline:focus {
    color: #fff !important;
    background-color: #f8cf52 !important;
    border-color: #f8cf52 !important;
    outline: 0;
}

.button-sm {
    font-size: 12px;
}


.buttons-mini-s {
    height: 28px;
    font-size: 12px;
    margin: 1em;
    padding: 0em 1em;
}

.social-icon {
    background-color: black;
    color: #fff;
    padding: 9px 11px;
    margin: 0;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    transition: 150ms ease;
}

.rounded-icon {
    color: #ffffff;
    background-color: #000;
    font-size: 17px;
    line-height: 45px !important;
    text-align: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    transition: all 0.3s ease 0s;
}

.social-icon:hover,
.rounded-icon:hover {
    background: var(--primary-color);
}

/* Input Styling*/
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.form-control:focus,
.form-control:active {
    border: 2px solid var(--primary-color) !important;
    outline: none;
    box-shadow: none !important;
}

.input-group-append:focus {
    outline: none;
}

.form-control {
    border: 2px solid var(--border-color) !important;
    border-radius: 0px !important;
    font-size: 16px;
}

fieldset {
    padding: 32px;
    border: 1px solid #ccc;
    scroll-margin: 32px;
}

fieldset:not(:last-child) {
    margin-bottom: 32px;
}

fieldset fieldset {
    padding: 16px;
    border-radius: 8px;
}

fieldset fieldset legend {
    font-size: 1em;
    padding: 0 8px;
    margin-left: -8px;
}

legend {
    font-size: 1.5em;
    font-weight: 500;
    margin-left: -16px;
    padding: 0 16px;
}

label {
    font-weight: 500;
    display: grid;
    align-items: center;
    font-size: 16px;
}



input,
select {
    font-family: inherit;
    padding: 8px;
    border: 1px solid #ccc;
}

input:focus,
select:focus {
    outline: none;
    border-color: #3498db;
}


.checkbox-label {
    display: inline-block;
}

#fieldset-billing-address {
    display: none;
}

.form-input {
    background: #fff !important;
    color: #000 !important;
    border: 2px solid rgb(229 229 229) !important;
    padding: 6px 10px !important;
    font: inherit !important;
    border-radius: 0px;
}

.form-input:focus,
.form-input:hover {
    border: 2px solid rgb(14, 125, 209) !important;
    box-shadow: none;
    outline: none;
    border-radius: 0px;
}

a:hover,
a {
    text-decoration: none !important;
}

.navbar-top-header-border {
    border-color: rgba(232, 232, 232, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.navbar-top-search-box {
    border: 2px solid rgba(185, 185, 185, .2);
    border-radius: 0;
    transition: border-color .5s ease;
    box-shadow: none;
    background-color: transparent;
}

.navbar-top-search-box>input:focus {
    border-color: var(--primary-color) !important;
    border-right: none;
    box-shadow: none;
}

.width-60 {
    width: 60% !important;
}

.padding-left-5 {
    padding-left: 5% !important;
}

.padding-left-15 {
    padding-left: 15% !important;
}

.padding-left-25 {
    padding-left: 25% !important;
}

.padding-right-5 {
    padding-right: 5% !important;
}

.topbar>ul li {
    list-style: square inside;
    padding: 0px;
    margin: 0px;
    line-height: 30px;
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

/* Logo */

.brand-logo-link {
    max-width: 245px;
    max-height: 120px;
}

ul#myTab-kv-1 li {
    padding-right: 10px;
}

.cf:before,
.cf:after {
    content: '';
    display: table;
}

.cf:after {
    clear: both;
}

.support {
    font-weight: bold;
    padding: 2em 0 0 0;
    font-size: 1.4em;
    color: #ee2563;
    display: none;
}

a {
    text-decoration: none;
    outline: none;
}



.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
}

.button-content {
    text-align: center;
    padding: 2em 1em 5em 2em;
    margin: 0 auto;
}

.button-content h2 {
    font-size: 2rem;
}

.content {
    padding: 2em 1em 2em 1em;
    z-index: 1;
    max-width: 1000px;
    margin: 0 auto;
}

.content h2 {
    margin: 0 0 2em;
}

.content p {
    margin: 1em 0;
    font-size: 16px !important;
}

.box {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
}

/* Header */
.codrops-header {
    padding: 3em 190px 4em;
    letter-spacing: -1px;
}

.codrops-header h1 {
    font-weight: 200;
    font-size: 4em;
    line-height: 1;
    margin-bottom: 0;
}

.codrops-header h1 span {
    display: block;
    font-size: 40%;
    letter-spacing: 0;
    padding: 0.5em 0 1em 0;
    color: #A8B3B8;
}

/* Top Navigation Style */
.codrops-links {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    font-size: 1.25em;
    text-align: center;
}

.codrops-links::after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: #BFCACF;
    content: '';
    -webkit-transform: rotate3d(0, 0, 1, 22.5deg);
    transform: rotate3d(0, 0, 1, 22.5deg);
}

.codrops-icon {
    display: inline-block;
    margin: 0.5em;
    padding: 0em 0;
    width: 1.5em;
    text-decoration: none;
}

.codrops-icon:before {
    margin: 0 5px;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: 'codropsicons';
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
}

.codrops-icon span {
    display: none;
}

.codrops-icon--drop:before {
    content: "\e001";
}

.codrops-icon--prev:before {
    content: "\e004";
}

/* Related demos */
.content--related {
    text-align: center;
    font-weight: 600;
}

.media-item {
    display: inline-block;
    padding: 1em;
    margin: 1em 0 0 0;
    vertical-align: top;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.media-item__img {
    opacity: 0.8;
    max-width: 100%;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.media-item:hover .media-item__img,
.media-item:focus .media-item__img {
    opacity: 1;
}

.media-item__title {
    font-size: 0.85em;
    margin: 0;
    padding: 0.5em;
}

@media screen and (max-width:50em) {
    .codrops-header {
        padding: 3em 10% 4em;
    }
}

@media screen and (max-width:40em) {
    .codrops-header h1 {
        font-size: 2.8em;
    }
}


/* #Header */

.home-slider {
    max-width: 1680px;
    clear: both;
    width: 100%;
    max-height: 510px;
    background-color: #f8f8f8ff;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-content {
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.morph-dropdown-wrapper .content>ul {
    display: flex;
    flex-wrap: wrap;
}

.topbar {
    border-bottom: 1px solid rgb(0 0 0 / 7%);
    padding: 10px 0px;
    font-size: 14px;
}

#header.topper-white {
    background: #ffffff;
    z-index: 990;
    padding: 0px;
    margin: 0px;
    position: relative;
    width: 100%;
    color: rgb(2, 0, 0);
}

#header.topper-white .topbar {
    color: var(--primary-color);
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

#header.topper-white .topbar a {
    color: var(--primary-color);
}

#cart-count {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 3px 5px;
    bottom: 19px;
    margin-left: -6px;
    position: relative;
}

.badge-sm {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.product-image-swiper {
    width: 92% !important;
}

.product-image-swiper .btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.rating-container .filled-stars {
    -webkit-text-stroke: darkgrey;
    text-shadow: none;
}

/* -------------------------------- 

Mega Menu

-------------------------------- */

.cd-morph-dropdown {
    position: relative;
    height: 35px;
    background-color: #FFFFFF;
}

.cd-morph-dropdown::before {
    /* never visible - used in JS to check mq */
    content: 'mobile';
    display: none;
}

.cd-morph-dropdown .nav-trigger {
    /* menu icon - visible on small screens only */
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    /* replace text with icon */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
}

.cd-morph-dropdown .nav-trigger span,
.cd-morph-dropdown .nav-trigger span::after,
.cd-morph-dropdown .nav-trigger span::before {
    /* these are the 3 lines of the menu icon */
    position: absolute;
    background-color: #1A1A1A;
    height: 3px;
    width: 26px;
}

.cd-morph-dropdown .nav-trigger span {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
}

.cd-morph-dropdown .nav-trigger span::after,
.cd-morph-dropdown .nav-trigger span::before {
    content: '';
    left: 0;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
}

.cd-morph-dropdown .nav-trigger span::before {
    -webkit-transform: translateY(-9px);
    -ms-transform: translateY(-9px);
    transform: translateY(-9px);
}

.cd-morph-dropdown .nav-trigger span::after {
    -webkit-transform: translateY(9px);
    -ms-transform: translateY(9px);
    transform: translateY(9px);
}

.cd-morph-dropdown.nav-open .nav-trigger span {
    background-color: transparent;
}

.cd-morph-dropdown.nav-open .nav-trigger span::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cd-morph-dropdown.nav-open .nav-trigger span::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.cd-morph-dropdown .main-nav {
    display: none;
}

.cd-morph-dropdown .morph-dropdown-wrapper {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 1.2em 5%;
    box-shadow: inset 0 1px 0 #e6e6e6;
    background-color: #FFFFFF;
}

.cd-morph-dropdown.nav-open .morph-dropdown-wrapper {
    display: block;
}

.cd-morph-dropdown .dropdown-list>ul>li {
    margin-bottom: 3.3em;
}

.cd-morph-dropdown .label {
    display: block;
    font-size: 2.2rem;
    color: #1A1A1A;
    margin-bottom: .8em;
}

.cd-morph-dropdown .content {
    padding-left: 30px;
    padding-right: 30px;
}

.cd-morph-dropdown .content li::after {
    clear: both;
    content: "";
    display: block;
    list-style-type: none;
}

.cd-morph-dropdown .content li {
    clear: both;
    content: "";
    display: block;
    list-style-type: none;
}

.cd-morph-dropdown .content li a {
    text-decoration: none;
    color: #000;
    line-height: 2rem;
}

.cd-morph-dropdown .content h2 {
    color: #a6a6a6;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
    margin: 20px 0 10px 14px;
}

.cd-morph-dropdown .content>ul>li>a {
    font-weight: 800;
    color: #333;
}

/* -------------------------------- 

Main site content

-------------------------------- */

/* Sidebar */
.shopping-cart-sidebar {
    max-width: 100%;
}

.sidenav,
.shopping-cart-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2000;
    top: 0;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: 150ms;
}

.sidenav {
    width: 400px;
    left: 0;
    max-width: 100%;
}

.shopping-cart-sidebar {
    width: 400px;
    right: 0px;
    max-width: 100%;
}

.is-closed-right {
    transform: translateX(400px);
}

.is-closed-left {
    transform: translateX(-400px);
}

.sidenav select {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    width: 80%;
    background: #fff;
    border: none;
    font-weight: 500;
}

.sidenav button {
    width: 20%;
    padding: 10px;
    background: #fff;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none;
    cursor: pointer;
    border: none;
}

.sidenav input:focus {
    outline: none !important;
}

.sidenav button:focus {
    outline: none;
}

.sidenav button:hover {
    background: #fff;
}

.sidenav .nav-link.active {
    width: auto;
    background-color: rgba(0, 0, 0, .05);
    color: #909090 !important;
    font-weight: 900;
    text-align: center;
    position: relative !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
}

.sidenav .nav-item {
    width: 50% !important;
    text-align: center !important;
}

.sidenav .nav-tabs {
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    background-color: rgba(0, 0, 0, .04) !important;
}

.sidenav .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 12px;
}

.sidenav .nav-item .nav-link {
    color: #909090;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-weight: 900;
    transition: background-color .25s ease, color .25s ease;
}

.sidenav .tab-content {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    left: 0;
    right: 0;
    bottom: 0;
}

.sidenav .select2-container {
    width: 100% !important;
}

.sidenav .close-sidenav a {
    color: black;
}

.shopping-cart-sidebar .shopping-cart-sm .product-image {
    float: right !important;
    width: auto !important;
}

.shopping-cart-sidebar .shopping-cart-sm .product-details {
    width: auto !important;
}



.shopping-cart-sidebar .shopping-cart-sm .product-sm-quantity:before {
    content: 'x' !important;
}


.shopping-cart-sidebar .shopping-cart-sm .product-sm-quantity input {
    margin-left: 20px !important;
    width: 60px !important;
}



.shopping-cart-sidebar .title {
    font-size: 18px;
}

.shopping-cart-sidebar .close-sidebar a {
    color: #000;
}

.block-div {
    width: 0px;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: rgb(26, 26, 27);
    z-index: 1000;
    text-align: center;
}

.vertical-nav .arrow {
    line-height: 25px;
    height: 25px;
}

/* colors */

.colors {
    width: 260px;
    float: left;
    margin: 20px auto;
}

.colors a {
    width: 43.3px;
    height: 30px;
    float: left;
}

.colors .default {
    background: #414956;
}

.colors .blue {
    background: #4A89DC;
}

.colors .green {
    background: #03A678;
}

.colors .red {
    background: #ED5565;
}

.colors .white {
    background: #fff;
}

.colors .black {
    background: #292929;
}

/* jquery-accordion-menu */

@import url(http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);

.jquery-accordion-menu,
.jquery-accordion-menu * {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: 0
}

.jquery-accordion-menu {
    min-width: 260px;
    position: relative;
    box-shadow: 0 20px 50px #333
}

.jquery-accordion-menu .jquery-accordion-menu-footer,
.jquery-accordion-menu .jquery-accordion-menu-header {
    width: 100%;
    height: 50px;
    padding-left: 22px;
    float: left;
    line-height: 50px;
    font-weight: 600;
    color: #f0f0f0;
    background: #414956
}

.jquery-accordion-menu ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.jquery-accordion-menu ul li {
    width: 100%;
    display: block;
    float: left;
    position: relative
}

.jquery-accordion-menu ul li a {
    width: 100%;
    padding: 14px 22px;
    float: left;
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: none;
    color: #000000;
    font-size: 13px;
    background: #fefeff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    -o-transition: color .2s linear, background .2s linear;
    -moz-transition: color .2s linear, background .2s linear;
    -webkit-transition: color .2s linear, background .2s linear;
    transition: color .2s linear, background .2s linear
}

.jquery-accordion-menu>ul>li.active>a,
.jquery-accordion-menu>ul>li:hover>a {
    color: #007bff;
}

.jquery-accordion-menu>ul>li>a {
    border-bottom: 1px solid rgba(129, 129, 129, .2);
}

.jquery-accordion-menu ul li a i {
    width: 34px;
    float: left;
    line-height: 18px;
    font-size: 16px;
    text-align: left
}

.jquery-accordion-menu .submenu-indicator {
    float: right;
    right: 22px;
    position: absolute;
    line-height: 19px;
    font-size: 20px;
    -o-transition: transform .3s linear;
    -moz-transition: transform .3s linear;
    -webkit-transition: transform .3s linear;
    -ms-transition: transform .3s linear
}

.jquery-accordion-menu ul ul.submenu .submenu-indicator {
    line-height: 16px
}

.jquery-accordion-menu .submenu-indicator-minus>.submenu-indicator {
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.jquery-accordion-menu ul ul.submenu,
.jquery-accordion-menu ul ul.submenu li ul.submenu {
    width: 100%;
    display: none;
    position: static
}

.jquery-accordion-menu ul ul.submenu li {
    clear: both;
    width: 100%
}

.jquery-accordion-menu ul ul.submenu li a {
    width: 100%;
    float: left;
    font-size: 11px;
    background: #f5f4f4;
    border-top: none;
    position: relative;
    border-left: solid 6px transparent;
    -o-transition: border .2s linear;
    -moz-transition: border .2s linear;
    -webkit-transition: border .2s linear;
    transition: border .2s linear
}

.jquery-accordion-menu ul ul.submenu li:hover>a {
    border-left-color: #007bff;
}

.jquery-accordion-menu ul ul.submenu>li>a {
    padding-left: 30px
}

.jquery-accordion-menu ul ul.submenu>li>ul.submenu>li>a {
    padding-left: 45px
}

.jquery-accordion-menu ul ul.submenu>li>ul.submenu>li>ul.submenu>li>a {
    padding-left: 60px
}

.jquery-accordion-menu ul li .jquery-accordion-menu-label,
.jquery-accordion-menu ul ul.submenu li .jquery-accordion-menu-label {
    min-width: 20px;
    padding: 1px 2px 1px 1px;
    position: absolute;
    right: 18px;
    top: 14px;
    font-size: 11px;
    font-weight: 800;
    color: #555;
    text-align: center;
    line-height: 18px;
    background: #f0f0f0;
    border-radius: 100%
}

.jquery-accordion-menu ul ul.submenu li .jquery-accordion-menu-label {
    top: 12px
}

.ink {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, .3);
    border-radius: 100%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none;
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none;
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
        -webkit-transform: none;
        transform: none;
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate-ink {
    -webkit-animation: ripple .5s linear;
    -moz-animation: ripple .5s linear;
    -ms-animation: ripple .5s linear;
    -o-animation: ripple .5s linear;
    animation: ripple .5s linear
}

@-webkit-keyframes ripple {
    100% {
        opacity: 0;
        -webkit-transform: scale(2.5)
    }
}

@-moz-keyframes ripple {
    100% {
        opacity: 0;
        -moz-transform: scale(2.5)
    }
}

@-o-keyframes ripple {
    100% {
        opacity: 0;
        -o-transform: scale(2.5)
    }
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5)
    }
}

.black.jquery-accordion-menu .jquery-accordion-menu-footer,
.black.jquery-accordion-menu .jquery-accordion-menu-header,
.black.jquery-accordion-menu ul li a {
    background: #292929
}

.black.jquery-accordion-menu>ul>li.active>a,
.black.jquery-accordion-menu>ul>li:hover>a {
    background: #222
}

.black.jquery-accordion-menu>ul>li>a {
    border-bottom-color: #222
}

.black.jquery-accordion-menu ul ul.submenu li:hover>a {
    border-left-color: #222
}

.category-section {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .16);
}

.category-section .category-section-title h3 {
    font-size: 32px;
    text-align: center;
}

.category-section .category-grid {
    margin-top: 10px;
    border: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 90%;
    text-align: center;
    padding: 0 0 60px;
    margin-left: auto;
    margin-right: auto;
}

.category-section .category-image {
    position: relative;
    transition: all 0.3s ease 0s;
    width: 100%;
    margin: auto;
}

.category-section .category-image-container {
    max-width: 195px;
    height: 130px;
    margin: auto;
    position: relative;
    vertical-align: middle;
}

.category-section .category-image img {
    max-width: 100%;
    margin: auto;
}

.category-image-container a img {
    max-width: 100%;
    max-height: 100%;
}

.category-section .social {
    color: #000 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: center !important;
    margin-top: 5px;
}

.category-image .social span {
    font-size: 0.8rem;
}

.product-section {
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .16);
}

.product-image-swiper-next,
.product-image-swiper-prev {
    border-radius: 2px !important;
    background: rgba(255, 255, 255) !important;
    color: rgba(0, 0, 0, 0.8) !important;
}

.product-image-swiper-next::after,
.product-image-swiper-prev::after {
    font-size: 33px;
}

.product-image-swiper>img {
    width: 100%;
    height: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    outline: none !important;
}


/* Swiper1 */

.swiper-container.swiper1 {
    height: 510px;
    background: transparent;
}

.swiper-slide img {
    max-width: 100%;
}

.swiper1.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper1 .swiper-slide>img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.swiper1 .swiper-slide>img {
    max-width: 100%;
    max-height: 100%;
}

.swiper1 .swiper-button-next:after,
.swiper1 .swiper-button-prev:after {
    font-size: 33px !important;
    font-weight: 100 !important;
}

.banner-swiper .swiper-button-next:after,
.banner-swiper .swiper-button-prev:after {
    font-size: 33px !important;
    font-weight: 100 !important;
}

.swiper1 .swiper-button-next,
.swiper1 .swiper-button-prev {
    border-radius: 2px !important;
    background: rgba(255, 255, 255, 0.8) !important;
    color: rgba(0, 0, 0, 0.8) !important;
}

.banner-swiper .swiper-button-next,
.banner-swiper .swiper-button-prev {
    border-radius: 2px !important;
    background: rgba(255, 255, 255, 0.8) !important;
    color: rgba(0, 0, 0, 0.8) !important;
}

.swiper1 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 0px;
    right: auto;
}

.swiper1 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 0px;
    left: auto;
}

.banner-swiper .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 0px;
    right: auto;
}

.banner-swiper .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 0px;
    left: auto;
}

.swiper1 .swiper-pagination-bullet {
    background-color: #fff;
}

.swiper1 .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
}

.swiper1 .swiper-pagination-bullet:focus {
    outline: none;
}

.swiper1 .swiper-pagination-bullet-active {
    background: #0675C9;
    opacity: 1;
    border-radius: 30%;
    transform: scaleX(1.8) scaley(0.8);
    border: none;
    box-shadow: none;
}

/* Swiper2 */

.swiper2 {
    width: 100%;
    height: 500px;
}

.swiper2.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper2.swiper-slide img {
    max-width: 100%;
    max-height: 100%;
}

/* category-swiper */

.category-swiper {
    width: 100%;
    height: 200px;
}

.category-swiper .swiper-slide {
    border-right: 1px solid rgba(255, 255, 255, .1);
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.category-swiper .swiper-slide img {
    max-width: 70%;
    max-height: 100%;
}

.gallery-top,
.product-gallery-top {
    height: 80%;
    width: 100%;
}

.product-gallery-thumbs,
.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.gallery-thumbs .swiper-slide,
.product-gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    margin-left: 10px;
}

.gallery-thumbs .product-view-grid,
.product-gallery-thumbs .product-view-grid {
    width: 100% !important;
    border: 0px !important;
}

.gallery-thumbs .swiper-slide-thumb-active,
.product-gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
}

/* Global settings */

.shopping-cart .product-image {
    float: left;
    width: 20%;
}

.shopping-cart .product-details {
    float: left;
    width: 37%;
}

.shopping-cart .product-price {
    float: left;
    width: 12%;
}

.shopping-cart .product-quantity {
    float: left;
    width: 10%;
}

.shopping-cart .product-removal {
    float: left;
    width: 9%;
}

.shopping-cart .product-line-price {
    float: left;
    width: 12%;
    text-align: right;
}

/* This is used as the traditional .clearfix class */

.group:before,
.shopping-cart:before,
.column-labels:before,
.product:before,
.totals-item:before,
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
    content: '';
    display: table;
}

.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
    clear: both;
}

.group,
.shopping-cart,
.column-labels,
.product,
.totals-item {
    zoom: 1;
}

/* Apply clearfix in a few places */
/* Apply dollar signs */

.product .product-price:before,
.product .product-line-price:before,
.totals-value:before {
    content: '₹ ';
}

.shopping-cart {
    margin-top: -45px;
}

/* Column headers */

.column-labels label {
    padding-bottom: 15px;
    padding-top: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
}

/* Product entries */

.product,
.product-sm {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.product .product-image,
.product-sm .product-image {
    text-align: center;
}

.product .product-image img,
.product-sm .product-image img {
    width: 100px;
}

.product .product-details,
.product-sm .product-details {
    line-height: 50px;
}

.product .product-details .product-title,
.product-sm .product-details .product-title {
    margin-right: 20px;
    font-family: inherit;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}

.product .product-details .product-description,
.product-sm .product-details .product-description {
    margin: 5px 110px 5px 0;
    line-height: 1.4em;
}

.product .product-quantity input,
.product-sm .product-quantity input {
    width: 60px;
}

.product .remove-product,
.product-sm .remove-product {
    border: 0;
    padding: 4px 8px;
    background-color: #c66;
    color: #fff;
    font-family: inherit;
    font-size: 12px;
    border-radius: 3px;
}

.product .remove-product:hover,
.product-sm .remove-product:hover {
    background-color: #a44;
}

/* Totals section */

.totals .totals-item {
    float: right;
    clear: both;
    width: 100%;
    margin-bottom: 10px;
}

.totals .totals-item label {
    float: left;
    clear: both;
    width: 79%;
    text-align: right;
}

.totals .totals-item .totals-value {
    float: right;
    width: 21%;
    text-align: right;
}

.featured-section {
    margin-right: 30px;
    margin-left: 30px;
}

.featured-section-product .swiper-slide img {
    width: 100%;
}

.featured-section-product .swiper-container {
    width: 100%;
    height: auto;
}

.featured-section-product .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;
    padding: 20px 0px 60px 0px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.featured-section-product>.swiper-button-prev,
.featured-section-product .swiper-button-next {
    display: none;
}

.featured-section-product-title {
    font-size: 35px;
    margin-top: 22px;
    margin-bottom: 22px;
    text-align: center;
    color: #2d2a2a;
    font-weight: 600;
    line-height: 1.4;
    border-bottom: none !important;
}

ul#myTab-kv-1 li>a {
    position: relative !important;
    color: #6b6666 !important;
    text-decoration: none !important;
    font-size: 18px;
}

ul#myTab-kv-1 li>a:hover {
    color: #000 !important;
}

ul#myTab-kv-1 li>a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

ul#myTab-kv-1 li>.active::before {
    visibility: visible;
    transform: scaleX(1);
}

ul#myTab-kv-1 li>a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

/* featured-section */


.featured-section .card {
    border: 1px solid #eee;
    cursor: pointer
}

.featured-section .weight {
    margin-top: -65px;
}

.featured-section .weight small {
    color: #e2dede
}

.featured-section .buttons {
    padding: 10px;
    border-radius: 4px;
    position: relative;
    margin-top: 7px;
    opacity: 0;
}

.featured-section .dot {
    height: 14px;
    width: 14px;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    left: 27%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: #fff;
    opacity: 0
}

.featured-section .cart-button {
    background-color: var(--primary-color);
    color: #fff;
}

.featured-section .cart-button:focus {
    box-shadow: none
}

.featured-section .cart {
    position: relative;
    width: 50px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 14px;
    overflow: hidden
}

.featured-section .card {
    border-radius: 0px;
    transition: 150ms;
}

.featured-section .cart-button.clicked span.dot {
    animation: item 0.3s ease-in forwards
}

@keyframes item {
    0% {
        opacity: 1;
        top: 30%;
        left: 30%
    }

    25% {
        opacity: 1;
        left: 26%;
        top: 0%
    }

    50% {
        opacity: 1;
        left: 23%;
        top: -22%
    }

    75% {
        opacity: 1;
        left: 19%;
        top: -18%
    }

    100% {
        opacity: 1;
        left: 14%;
        top: 28%
    }
}

.featured-section .card:hover .buttons {
    opacity: 1
}

.featured-section .card:hover .weight {
    margin-top: 10px
}

.featured-section .card:hover {
    transform: scale(1.02);
    z-index: 2;
    overflow: hidden;
}

/* Footer */

footer {
    background-color: #000;
}

.copyright a {
    color: #fff;
}

.copyright {
    background-color: var(--primary-color);
    color: #fff;
}

/* ---------------------------------------------------------------------------------------------- 
    Product-listing 
*/

.product-listing .title {
    font-size: 16px;
}

.filter-bars {
    display: none;
}

.sidebar-filter {
    display: none;
}

#breadcrumbs {
    list-style: none;
    display: flex;
    padding: 8px 16px;
}

#breadcrumbs li {
    margin: 8px 0px 8px 0px;
}

#breadcrumbs li a {
    color: #4b7bec;
    text-decoration: none;
}

#breadcrumbs li a:hover {
    transition-delay: 50ms;
    transition: 50ms;
    color: #3867d6;
    text-decoration: underline;
}

#breadcrumbs li+li:before {
    padding: 8px;
    color: #000;
    content: "/\00a0";
}

.filter-section {
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .16);
}

.price-filter-control .range-slider {
    margin: auto;
    text-align: center;
    position: relative;
    height: 6em;
}

.price-filter-control .range-slider svg,
.price-filter-control .range-slider input[type=range] {
    position: absolute;
    left: 0;
    bottom: 0;
}

.price-filter-control input[type=number] {
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1.6em;
    -moz-appearance: textfield;
}

.price-filter-control input[type=number]::-webkit-outer-spin-button,
.price-filter-control input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.price-filter-control input[type=number]:invalid,
.price-filter-control input[type=number]:out-of-range {
    border: 2px solid #ff6347;
}

.price-filter-control input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}

.price-filter-control input[type=range]:focus {
    outline: none;
}

.price-filter-control input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--primary-color);
}

.price-filter-control input[type=range]:focus::-ms-fill-lower {
    background: var(--primary-color);
}

.price-filter-control input[type=range]:focus::-ms-fill-upper {
    background: var(--primary-color);
}

.price-filter-control input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: var(--primary-color);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.price-filter-control input[type=range]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid var(--primary-color);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #D4ECFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

.price-filter-control input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #8a8e91;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.price-filter-control input[type=range]::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid var(--primary-color);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}

.price-filter-control input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.price-filter-control input[type=range]::-ms-fill-lower,
.price-filter-control input[type=range]::-ms-fill-upper {
    background: var(--primary-color);
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

.price-filter-control input[type=range]::-ms-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid var(--primary-color);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #D4ECFF;
    cursor: pointer;
}

.custom-control-label {
    color: black;
}

.product-listing-section .product-grid {
    padding: 0;
    border: none;
    width: 50%;
    height: 100%;
    box-shadow: none;
}

.product-listing-section .product-listing {
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
}

.product-listing-section .product-listing:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.product-content {
    padding-bottom: 10px;
}

.check-availability {
    margin: 2px 5px;
}

.product-listing-section .product-content .price {
    font-size: 25px !important;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.product-listing-section .percentage-off {
    color: rgb(0, 123, 255);
    font-size: 15px;
}

.product-listing-section .title {
    font-size: 25px !important;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin: auto;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    height: 40px;
}

.product-listing-section .subtitle {
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin: auto;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    max-height: 40px;
    color: rgb(29, 28, 28)
}

.product-listing-section .rating-sm {
    font-size: 10px;
}

.product-listing-section .striped-price {
    color: #999;
    font-size: 13px;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 3px;
    display: inline-block;
}

.product-listing-section .title a {
    color: #000;
}

.product-listing-section .product-grid .product-image {
    position: relative;
    transition: all 0.3s ease 0s;
    width: 100%;
    margin: auto;
    height: 100%;
}

/* ----------------------------------------------------------------------------------------------
    Product-Page-widget
*/

#page-title.page-title-mini {
    padding: 30px 0;
}

#page-title {
    position: relative;
    padding: 4rem 0;
    background-color: #d4ecff;
}

.breadcrumb {
    position: absolute !important;
    width: auto !important;
    top: 50% !important;
    left: auto !important;
    right: 15px !important;
    margin: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
    font-size: 90%;
    transform: translateY(-50%);
}

.breadcrumb-item {
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
}

.product-page-content .product-title {
    font-size: 32px !important;
    font-weight: 400;
}

.product-page-content .card {
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 0px;
}

.product-page-content>.product-page-details .btn.focus,
.product-page-content>.product-page-details .btn:focus {
    outline: 0 !important;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .075) !important;
    border: .5px solid #9b1717 !important;
}

.product-page-content .product-image {
    max-width: 100%;
    height: auto;
    width: inherit !important;
}

.product-page-content .product-image-thumbs {
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
}

.product-page-content .product-image-thumb {
    box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
    border-radius: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    display: -ms-flexbox;
    display: flex;
    margin-right: 1rem;
    max-width: 7rem;
    padding: .5rem;
}

.product-page-content .product-image-thumbs img {
    max-width: 100%;
    height: auto;
    -ms-flex-item-align: center;
    align-self: center;
}

.product-preview-image-section-md .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.product-preview-image-section-md .product-view-image-container {
    max-width: 100%;
    height: 230px;
    margin: auto;
    position: relative;
    vertical-align: middle;
    line-height: 250px;
    ;
}

.product-preview-image-section-md .product-view-image-container img {
    max-width: 100%;
    max-height: 100%;
    position: relative !important;
}

.product-preview-image-section-md .product-view-image-container img:hover {
    cursor: pointer;
}

.zoomWrapper {
    margin: auto;
}

.zoomWindow {
    border: 2px solid rgb(14 125 209) !important;
}

.product-preview-image-section-md .product-view-image {
    position: relative;
    transition: all 0.3s ease 0s;
    width: 100%;
    margin: auto;
    height: 280px;
}

.product-preview-image-section-md .product-view-grid {
    text-align: center;
    padding: 0 0 0px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.product-preview-image-section-md .swiper-slide {
    background-size: cover;
    background-position: center;
}

.product-preview-image-section-sm {
    display: none;
}

.product-page-details label.btn.btn-default {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: inherit;
    margin: 2px;
}

.product-page-details .btn.focus,
.product-page-details .btn:focus {
    outline: 0;
    color: white;
    box-shadow: none;
    background-color: var(--primary-color);
}

.product-page-details .btn-group>.active {
    background-color: var(--primary-color);
    color: white;
}

.product-page-details .btn-group,
.product-page-details .btn-group-vertical {
    display: block;
}

.product-page-details .price {
    font-size: 25px;
}

.product-page-details .striped-price {
    font-size: 15px;
}

.product-page-details .percentage-off {
    color: rgb(0, 123, 255);
    font-size: 15px;
}

.num-in {
    background: #FFFFFF;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 110px;
    float: left;
    margin-top: -22px;
}

.num-in span {
    width: 30%;
    display: block;
    height: 40px;
    float: left;
    position: relative;
}

.num-in span:before,
.num-in span:after {
    content: '';
    position: absolute;
    background-color: #667780;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -5px;
}

.num-in span.plus:after {
    transform: rotate(90deg);
}

.num-in input {
    float: left;
    width: 35%;
    height: 36px;
    border: none;
    text-align: center;
    font-weight: 900;
    color: #b0b0b0;
}

.num-in input:focus {
    color: black;
    outline: none;
}

.style-3-product-right-lg .product-grid .product-image {
    height: 80% !important;
}

/* ----------------------------------------------------------------------------------------------
        Error 404
    */

.error_404 {
    text-align: center;
    background-color: rgb(255, 255, 255);
    height: auto;
}

.error_404:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    height: 100%;
}

.error_404 h1 {
    display: inline-block;
    color: rgb(0, 0, 0);
    font-size: 12em;
    text-shadow: .03em .03em 0 rgb(255, 255, 255);
}

.error_404 h1:after {
    content: attr(data-shadow);
    position: absolute;
    top: .06em;
    left: .06em;
    z-index: -1;
    text-shadow: none;
    background-image: linear-gradient(45deg, transparent 45%, hsla(48, 20%, 90%, 1) 45%, hsla(48, 20%, 90%, 1) 55%, transparent 0);
    background-size: .05em .05em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shad-anim 15s linear infinite alternate;
    -moz-animation: shad-anim 15s linear infinite alternate;
    -ms-animation: shad-anim 15s linear infinite alternate;
    -o-animation: shad-anim 15s linear infinite alternate;
    animation: shad-anim 15s linear infinite alternate;
}

/* shipping section */
.custom-row {
    margin-bottom: -40px !important;
    background-color: var(--primary-color);
}

.column_container {
    padding-left: 0;
    padding-right: 0;
}

.column_container .column_inner {
    box-sizing: border-box;
    padding-right: 15px;
    width: 100%;
}

.custom_column {
    border-right-width: 1px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    border-right-color: rgba(255, 255, 255, .15) !important;
    border-right-style: solid !important;
}

.text-center .inline-element {
    margin-right: 7px;
    margin-left: 7px;
}

.inline-element {
    display: inline-block;
    vertical-align: middle;
}

.info-box-wrapper.inline-element {
    max-width: 100%;
}

.custom {
    margin-bottom: 0px !important;
}

.box-icon {
    flex-direction: row;
}

.info-box {
    display: flex;
    align-items: stretch;
}

.light-color {
    color: rgba(255, 255, 255, .8);
}

.info-box-icon {
    overflow: hidden;
}

.svg-wrapper {
    display: inline-block;
}

.secure-svg-wrapper {
    margin-left: 29px;
}

.info-box-content {
    position: relative;
    flex: 1 1 auto;
}

.info-box-title {
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    line-height: 1.4;
    font-weight: 700;
}

.cls-1 {
    fill: #fff;
    fill-rule: evenodd;
}

.info-box-content h4 {
    color: #fff;
}

/*----------------------------------------------------------------------------------------------------------
    */

/* listing-page-widget */

.listing-page .menu {
    display: block;
    position: relative;
    background-color: #f8f9fa;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.listing-page .menu__line {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 2px;
    background-color: rgb(0, 0, 0);
    border-radius: 13px;
    transition-duration: 0.4s;
}

.listing-page .menu__line:nth-child(1) {
    top: 13px;
}

.listing-page .menu__line:nth-child(2) {
    top: 20px;
}

.listing-page .menu__line:nth-child(3) {
    bottom: 11px;
}

.listing-page .menu.active {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    position: absolute;
    z-index: 10001;
}

.listing-page .menu.active .menu__line:nth-child(1) {
    transform: translate(-11px, 6px) rotate(-46deg);
}

.listing-page .menu.active .menu__line:nth-child(2) {
    transition-duration: 0s;
    opacity: 0;
}

.listing-page .menu.active .menu__line:nth-child(3) {
    transform: translate(-11px, -8px) rotate(45deg);
}

.listing-page .filter-nav {
    position: absolute;
    background-color: #ffffff;
    height: 50px;
    width: 50px;
    z-index: 10;
    transition-duration: 0.4s;
    visibility: visible;
}

.filter-nav {
    position: fixed !important;
}

.listing-page .filter-nav.open {
    background-color: rgba(255, 255, 255, 1);
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    border-radius: 0;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    padding: 25px;
    z-index: 9999;
    visibility: visible;
}

.listing-page .filter_nav__list {
    display: none;
}

.listing-page .filter_nav__list.show {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 100%;
}

.listing-page .nav__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    opacity: 0;
    animation-name: fadein;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.listing-page .nav__item:nth-child(1) {
    animation-delay: 0.2s;
}

.listing-page .nav__item:nth-child(2) {
    animation-delay: 0.3s;
}

.listing-page .nav__item:nth-child(3) {
    animation-delay: 0.4s;
}

.listing-page .nav__item:nth-child(4) {
    animation-delay: 0.5s;
}

.listing-page .nav__item:not(:last-child) {
    margin-bottom: 32px;
}

.listing-page .nav__link {
    font-size: 24px;
    letter-spacing: 0.1em;
    text-decoration: none;
    color: #fff;
}

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateY(24px);
    }

    100% {
        opacity: 1;
    }
}

/*------------------------------------ Dashboard Page --------------------------------------------*/

.settings-tab .nav-pills-custom .nav-link {
    color: #726e6e;
    background: #fff;
    position: relative;
}

.settings-tab .nav-pills-custom .nav-link.active {
    color: var(--primary-color);
    background: #fff;
}

.settings-tab .nav {
    font-size: larger;
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .16);
}

.dashboard-content .dashboard-icon {
    font-size: 70px;
    color: var(--primary-color);
}

.dashboard-content .card a {
    color: #050505;
}

.settings-tab-content .card {
    position: relative;
}

.orders-section p {
    font-size: 16px;
    margin-bottom: 7px
}

.orders-section .cursor-pointer {
    cursor: pointer
}

.orders-section a {
    text-decoration: none !important;
}

.orders-section .bold {
    font-weight: 600;
    font-size: 13px;
}

.orders-section .small {
    font-size: 12px !important;
    letter-spacing: 0.5px !important
}

.orders-section .Today {
    color: rgb(83, 83, 83)
}

.orders-section .btn-outline-primary {
    background-color: #fff !important;
    color: #4bb8a9 !important;
    border: 1.3px solid #4bb8a9;
    font-size: 12px;
    border-radius: 0.4em !important
}

.orders-section .btn-outline-primary:hover {
    background-color: #4bb8a9 !important;
    color: #fff !important;
    border: 1.3px solid #4bb8a9
}

.orders-section .btn-outline-primary:focus,
.orders-section .btn-outline-primary:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #42A5F5 !important
}

.orders-section #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

.orders-section #progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 400;
    color: #455A64 !important
}

.orders-section #progressbar li p {
    font-size: 0.7rem;
}

.orders-section #progressbar #step1:before {
    content: "1";
    color: #fff;
    width: 29px;
    margin-left: 15px !important;
    text-align: center;
}

.orders-section #progressbar #step2:before {
    content: "2";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step3:before {
    content: "3";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step4:before {
    content: "4";
    color: #fff;
    width: 29px;
    margin-right: 15px !important;
    text-align: center;
}

.orders-section #progressbar #step5:before {
    content: "5";
    color: #fff;
    width: 29px;
    margin-right: 15px !important;
    text-align: center;
}

.orders-section #progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #bcbcbc;
    border-radius: 50%;
    margin: auto
}

.orders-section #progressbar li:after {
    content: '';
    width: 130%;
    height: 2px;
    background: #bcbcbc;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 15px;
    z-index: -1
}

.orders-section #progressbar li:nth-child(2):after {
    left: 55%
}

.orders-section #progressbar li:nth-child(1):after {
    left: 15%;
    width: 130%
}

.orders-section #progressbar li:nth-child(3):after {
    left: 25% !important;
}

.orders-section #progressbar li:nth-child(4):after {
    left: -6% !important;
    width: 91% !important
}

.orders-section #progressbar li:nth-child(5):after {
    left: -6% !important;
    width: 91% !important
}

.orders-section #progressbar li.active:before,
.orders-section #progressbar li.active:after {
    background: #0e7dd1
}

.orders-section .card {
    background-color: #fff;
    z-index: 0
}

.orders-section small {
    font-size: 12px !important
}

.orders-section .a {
    justify-content: space-between !important
}

.orders-section .border-line {
    border-right: 1px solid rgb(226, 206, 226)
}

.orders-section .card-footer img {
    opacity: 0.3
}

.orders-section .card-footer h5 {
    font-size: 1.1em;
    color: #8C9EFF;
    cursor: pointer
}

.banner-swiper .swiper-slide>img {
    width: 100%;
    height: auto;
}

.call-to-action-section {
    background: linear-gradient(to right, var(--primary-color), #607D8B);
}

.iphone-slider {
    width: 296px;
    height: 620px;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 2.5rem;
    margin-left: auto;
    background-size: cover;
}

.imageSliderHeader {
    width: 263px;
    padding-top: 70px;
}

.imageSliderHeader>.swiper-slide {
    height: 100%;
}

.imageSliderHeader img {
    width: 100%;
    height: 480px;
    ;
}

.call-to-action-section .header-h1 {
    margin-bottom: 1.5rem;
    font: 600 3rem/3.625rem "Fira Sans", sans-serif;
    color: #fff;
}

.call-to-action-section .header-p {
    color: #fff;
    margin-bottom: 5px;
}

.call-to-action-section .text-area {
    text-align: center;
}

.call-to-action-section .apple-store {
    display: inline-block;
    width: 224px;
    height: 56px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.625rem;
    background: url('http://eshop.wrteam.in/assets/front_end/temp/apple-store.png');
    background-size: cover;
    transition: all 0.2s;
}

.call-to-action-section .google-store {
    display: inline-block;
    width: 224px;
    height: 56px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.625rem;
    background: url('http://eshop.wrteam.in/assets/front_end/temp/google-store.png');
    background-size: cover;
    transition: all 0.2s;
}

.wrap {
    width: 500px;
    margin: 2em auto;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.select2-result-repository {
    padding-top: 4px;
    padding-bottom: 3px;
}

.select2-result-repository__avatar {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    text-align: center !important;
}

.select2-result-repository__avatar img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
}

.select2-result-repository__meta {
    margin-left: 70px;
}

.select2-result-repository__title {
    word-wrap: break-word;
    line-height: 1.1;
    margin-bottom: 4px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers {
    margin-right: 1em;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
    display: inline-block;
    color: #aaa;
    font-size: 11px;
}

.select2-result-repository__description {
    font-size: 13px;
    color: #777;
    margin-top: 4px;
}

.select2-results__option--highlighted {
    opacity: 1 !important;
}

.select2-results__option--highlighted .select2-result-repository__title {
    color: rgb(31, 30, 30);
    color: black;
    font-weight: bold;
}

.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-results__option--highlighted .select2-result-repository__description,
.select2-results__option--highlighted .select2-result-repository__watchers {
    color: rgb(31, 30, 30);
}

.select2-container--adwitt .select2-results>.select2-results__options {
    max-height: 300px;
}

.select2-selection__placeholder {
    color: #bcbbbb;
}

.select2-container .select2-selection--single {
    margin-top: 8px;
}

.select2-results__options {
    overflow-y: auto;
}

.select2-dropdown {
    z-index: 9999;
    border: 0px solid var(--border-color);
    border-radius: 0px;
}

.select2-search--dropdown .select2-search__field {
    border-radius: 0px;
}

.select2-results__option {
    opacity: 0.8;
    transition: 150ms;
}

/* form styling */
/* form-style-1 */
.form-style-1 {
    margin: 10px auto;
    max-width: 400px;
    padding: 20px 12px 10px 20px;
    font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.form-style-1 li {
    padding: 0;
    display: block;
    list-style: none;
    margin: 10px 0 0 0;
}

.form-style-1 label {
    margin: 0 0 3px 0;
    padding: 0px;
    display: block;
    font-weight: bold;
}

.form-style-1 input[type=text],
.form-style-1 input[type=date],
.form-style-1 input[type=datetime],
.form-style-1 input[type=number],
.form-style-1 input[type=search],
.form-style-1 input[type=time],
.form-style-1 input[type=url],
.form-style-1 input[type=email],
textarea,
select {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #BEBEBE;
    padding: 7px;
    margin: 0px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
}

.form-style-1 input[type=text]:focus,
.form-style-1 input[type=date]:focus,
.form-style-1 input[type=datetime]:focus,
.form-style-1 input[type=number]:focus,
.form-style-1 input[type=search]:focus,
.form-style-1 input[type=time]:focus,
.form-style-1 input[type=url]:focus,
.form-style-1 input[type=email]:focus,
.form-style-1 textarea:focus,
.form-style-1 select:focus {
    -moz-box-shadow: 0 0 8px #88D5E9;
    -webkit-box-shadow: 0 0 8px #88D5E9;
    box-shadow: 0 0 8px #88D5E9;
    border: 1px solid #88D5E9;
}

.form-style-1 .field-divided {
    width: 49%;
}

.form-style-1 .field-long {
    width: 100%;
}

.form-style-1 .field-select {
    width: 100%;
}

.form-style-1 .field-textarea {
    height: 100px;
}

.form-style-1 input[type=submit],
.form-style-1 input[type=button] {
    background: #4B99AD;
    padding: 8px 15px 8px 15px;
    border: none;
    color: #fff;
}

.form-style-1 input[type=submit]:hover,
.form-style-1 input[type=button]:hover {
    background: #4691A4;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.form-style-1 .required {
    color: red;
}

/* form-style-2 */

.form-style-10 {
    width: 450px;
    padding: 30px;
    margin: 40px auto;
    background: #FFF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.form-style-10 .inner-wrap {
    padding: 30px;
    background: #F8F8F8;
    border-radius: 6px;
    margin-bottom: 15px;
}

.form-style-10 h1 {
    background: #2A88AD;
    padding: 20px 30px 15px 30px;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    font: normal 30px 'Bitter', serif;
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #257C9E;
}

.form-style-10 h1>span {
    display: block;
    margin-top: 2px;
    font: 13px Arial, Helvetica, sans-serif;
}

.form-style-10 label {
    display: block;
    font: 13px Arial, Helvetica, sans-serif;
    color: #888;
    margin-bottom: 15px;
}

.form-style-10 input[type="text"],
.form-style-10 input[type="date"],
.form-style-10 input[type="datetime"],
.form-style-10 input[type="email"],
.form-style-10 input[type="number"],
.form-style-10 input[type="search"],
.form-style-10 input[type="time"],
.form-style-10 input[type="url"],
.form-style-10 input[type="password"],
.form-style-10 textarea,
.form-style-10 select {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: 2px solid #fff;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
}

.form-style-10 .section {
    font: normal 20px 'Bitter', serif;
    color: #2A88AD;
    margin-bottom: 5px;
}

.form-style-10 .section span {
    background: #2A88AD;
    padding: 5px 10px 5px 10px;
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 4px solid #fff;
    font-size: 14px;
    margin-left: -45px;
    color: #fff;
    margin-top: -3px;
}

.form-style-10 input[type="button"],
.form-style-10 input[type="submit"] {
    background: #2A88AD;
    padding: 8px 20px 8px 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    font: normal 30px 'Bitter', serif;
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #257C9E;
    font-size: 15px;
}

.form-style-10 input[type="button"]:hover,
.form-style-10 input[type="submit"]:hover {
    background: #2A6881;
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
}

.form-style-10 .privacy-policy {
    float: right;
    width: 250px;
    font: 12px Arial, Helvetica, sans-serif;
    color: #4D4D4D;
    margin-top: 10px;
    text-align: right;
}

/* form-style-3 */

.form-style-5 {
    max-width: 500px;
    padding: 10px 20px;
    background: #f4f7f8;
    margin: 10px auto;
    padding: 20px;
    background: #f4f7f8;
    border-radius: 8px;
}

.form-style-5 fieldset {
    border: none;
}

.form-style-5 legend {
    font-size: 1.4em;
    margin-bottom: 10px;
}

.form-style-5 label {
    display: block;
    margin-bottom: 8px;
}

.form-style-5 input[type="text"],
.form-style-5 input[type="date"],
.form-style-5 input[type="datetime"],
.form-style-5 input[type="email"],
.form-style-5 input[type="number"],
.form-style-5 input[type="search"],
.form-style-5 input[type="time"],
.form-style-5 input[type="url"],
.form-style-5 textarea,
.form-style-5 select {
    font-family: Georgia, "Times New Roman", Times, serif;
    background: rgba(255, 255, 255, .1);
    border: none;
    border-radius: 4px;
    font-size: 15px;
    margin: 0;
    outline: 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: #e8eeef;
    color: #8a97a0;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom: 30px;
}

textarea {
    max-width: 100%;
}

.form-style-5 input[type="text"]:focus,
.form-style-5 input[type="date"]:focus,
.form-style-5 input[type="datetime"]:focus,
.form-style-5 input[type="email"]:focus,
.form-style-5 input[type="number"]:focus,
.form-style-5 input[type="search"]:focus,
.form-style-5 input[type="time"]:focus,
.form-style-5 input[type="url"]:focus,
.form-style-5 textarea:focus,
.form-style-5 select:focus {
    background: #d2d9dd;
}

.form-style-5 select {
    -webkit-appearance: menulist-button;
    height: 35px;
}

.form-style-5 .number {
    background: #1abc9c;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    border-radius: 15px 15px 15px 0px;
}

.form-style-5 input[type="submit"],
.form-style-5 input[type="button"] {
    position: relative;
    display: block;
    padding: 19px 39px 18px 39px;
    color: #FFF;
    margin: 0 auto;
    background: #1abc9c;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    width: 100%;
    border: 1px solid #16a085;
    border-width: 1px 1px 3px;
    margin-bottom: 10px;
}

.form-style-5 input[type="submit"]:hover,
.form-style-5 input[type="button"]:hover {
    background: #109177;
}

.form-text-style {
    text-align: center;
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 10px;
}

/* product grid */

.prev,
.next {
    position: fixed;
    top: 0;
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 999;
    color: rgba(255, 255, 255, .5);
    font-size: 8rem;
    transition: all .2s;
}

.prev:hover,
.next:hover {
    color: rgba(255, 255, 255, .8);
}

.prev {
    left: -25%;
}

.next {
    right: -25%;
}

.active .prev {
    left: 0;
}

.active .next {
    right: 0;
}

.grid {
    margin: 10vh auto;
    max-width: 60%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
}

.grid-div {
    text-align: center;
    padding: 1rem 0;
    color: white;
    font-size: 3rem;
    text-transform: uppercase;
    background: rgba(0, 0, 0, .2);
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.grid-div .grid_img {
    display: block;
    object-fit: cover;
    object-position: center;
    transition: all .5s;
}

.grid-div.show::before {
    content: '';
    display: block;
    position: fixed;
    z-index: 555;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
}

.grid-div.show .grid_img {
    position: fixed;
    top: 0;
    left: 0;
    margin: 30px;
    align-self: center;
    object-fit: contain;
    z-index: 666;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    filter: drop-shadow(0 3px 15px black);
    cursor: url(close-button.png), grab;
    /* custom cursor to signal close on click */
}

.grid-div:not(.show):hover .grid_img {
    cursor: pointer;
    transform: scale(1.3);
}


.horizontal {
    grid-column: span 2;
}

.vertical {
    grid-row: span 2;
}

.big {
    grid-column: span 2;
    grid-row: span 2;
}

.active .grid-div:not(.show) .grid_img {
    filter: blur(3px);
}

/* product-widgets */

.bg-light-clr {
    background: #fff !important;
}

.widget-image {
    width: 90px;
    height: 90px;
    line-height: 90px;
}

.widget-image img {
    max-width: 100%;
    max-height: 100%;
}

.spacing {
    margin-right: -3px;
    margin-left: -3px;
}

.spacing>[class*=col] {
    margin-bottom: 6px;
    padding-right: 3px;
    padding-left: 3px;
}

.wrap-picture {
    position: relative;
    overflow: hidden;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 800px;
    perspective: 800px;
}

.left-border {
    border-right: 2px solid rgba(119, 119, 119, .17);
}

.follow-us h1 {
    font-weight: bolder;
}

.follow-us {
    margin: auto 0;
}

.line-container {
    border-bottom: 2px solid rgba(119, 119, 119, .17);
}

.product-widget-title {
    border-color: var(--primary-color) !important;
    display: inline-block;
    margin-bottom: -2px;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
}

.rating li {
    color: #ffd200;
}

.cnt {
    max-width: 1222px;
}

.cnt {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.column-container {
    padding-left: 0;
    padding-right: 0;
}

.column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.title-wrapper {
    margin-bottom: 30px;
}

.title-container {
    font-size: 18px;
}

.widget:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.see-all-category {
    font-weight: bolder !important;
    padding: 8px 18px !important;
}

.ul-line-height {
    line-height: 2.4;
}

ol,
ul {
    line-height: 1.4;
    list-style: none;
}

.product-wrap {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin-bottom: 7px;
}

.product-title {
    font-size: 14px;
    color: #000;
    transition: 150ms ease;
    line-height: 32px;
}

.product-descriptions {
    line-height: 17px;
    margin-top: 11px;
}

del .amount {
    color: #BDBDBD;
    font-weight: 400;
}

.product-wrap img {
    margin-bottom: 12px;
    cursor: pointer;
}

.product-info {
    margin-left: 14px;
}

ins .amount {
    color: var(--primary-color);
    font-weight: 700;
}

.border-line {
    border-bottom: 1px solid rgba(129, 129, 129, .2) !important;
    margin-bottom: 12px !important;
}

.price del {
    color: #BDBDBD;
    font-size: 90%;
}

.price ins {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    opacity: 1;
}

/* vertical navigation */

.dp-icon {
    font-weight: 600;
    color: rgba(82, 82, 82, .45);
    font-style: normal;
    font-size: 80%;
}

.morph-dropdown-wrapper {
    z-index: 999;
}

.header-categories-nav.opened-menu .categories-menu-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    pointer-events: visible;
    pointer-events: unset;
}

.header-categories-nav .categories-menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 299;
    opacity: 0;
    transition: all .2s ease;
    -webkit-transform: translateY(15px) translateZ(0);
    transform: translateY(15px) translateZ(0);
    pointer-events: none;
}

.menu-opener {
    border-bottom-style: solid;
}

.header-bottom {
    border-color: rgba(232, 232, 232, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top: 1px solid var(--border-color);
}

.column {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.col-left {
    justify-content: flex-start;
}

.header-categories-nav .header-categories-nav-wrap {
    position: relative;
}

.header-categories-nav.menu-opener {
    cursor: default;
}

.menu-opener {
    height: 54px;
    margin-top: -1px;
    margin-bottom: -1px;
}

.menu-opener .burger,
.menu-opener .burger::after,
.menu-opener .burger::before {
    display: inline-block;
    width: 18px;
    height: 2px;
    transition: none;
}

.menu-opener .burger {
    flex: 0 0 auto;
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
}

.menu-opener .burger i {
    margin-right: 10px;
}

.menu-opener .menu-open-label {
    flex: 1 1 auto;
    margin-right: 10px;
}

.categories-menu-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    pointer-events: visible;
    pointer-events: unset;
}

.header-categories-nav .categories-menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 299;
    opacity: 0;
    transition: all .2s ease;
    -webkit-transform: translateY(15px) translateZ(0);
    transform: translateY(15px) translateZ(0);
    pointer-events: none;
}

.vertical-navigation {
    position: relative;
}

.header-categories-nav .categories-menu-dropdown .menu {
    border-top: none;
}

.vertical-navigation .menu {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    border: 1px solid rgba(129, 129, 129, .2);
    background-color: #FFF;
}

.whb-col-center {
    flex: 1 1 0%;
}

.main-nav {
    flex: 1 1 auto;
}

.menu-left {
    text-align: left;
}

/* vertical nav */

.vertical-nav {
    text-align: left;
    width: 234px;
    list-style: none;
    padding: 0px;
    position: relative;
    border: 1px solid var(--border-color);
    display: contents !important;
}

.vertical-nav>li, .vertical-nav>a {
    background: #FFFFFF;
    display: block;
    font-size: 14px;
    padding: 0px 5px 0px 10px;
    cursor: pointer;
    font-weight: 400;
    border-bottom: 1px solid #DCDCDC;
}

.vertical-nav>li:hover, .vertical-nav>a:hover {
    background: #F9F9F9;
    transition: 150ms;
}

.arrow {
    float: right;
}

.vertical-nav>li>.v-conatiner, .vertical-nav>a>.v-conatiner {
    border: 1px solid #CCCCCC;
    padding: 0;
    position: absolute;
    top: 0px;
    left: 270px;
    width: 503px;
    padding: 10px 0px 20px 20px;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
}

.vertical-nav>li:hover .v-conatiner, .vertical-nav>a:hover .v-conatiner {
    display: block;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 150ms ease-in-out;
    -moz-transition: opacity 150ms ease-in-out;
    -ms-transition: opacity 150ms ease-in-out;
    -o-transition: opacity 150ms ease-in-out;
    transition: opacity 150ms ease-in-out;
    z-index: 1;
    cursor: pointer;
}

.cd-dp {
    background-color: #fff !important;
}

.morph-text a {
    color: #333 !important;
}

.column.col-center {
    flex: 1 1 0%;
}

.nav-image {
    max-width: 30%;
}

.nav-image {
    max-width: 30%;
}

.column-inner {
    z-index: auto;
}

.column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.column-inner {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1;
}

.image-left {
    text-align: left;
}

.image {
    margin-bottom: 10px !important;
}

.figure-class {
    display: inline-block;
    vertical-align: top;
}

.image-wrapper {
    display: block;
    width: 150px;
    height: 150px;
    text-align: center;
    position: relative;
    line-height: 150px;
}

.nav-image {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.sub-menu li {
    flex: 0 0 220px;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 200;
}

a.nav-text-title {
    color: #333;
    font-weight: bold;
}

ul.sub-submenu li:hover {
    transition: 150ms;
}

ul.sub-submenu li {
    font-weight: 500;
    font-size: 13px;
    line-height: 2.9;
}

.v-conatiner {
    min-height: 100%;
    width: 825px !important;
}

.sub-submenu {
    color: #777 !important;
    line-height: 1.9;
}

.image-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.header-categories-nav {
    cursor: pointer;
}

/* vertical nav header */

.arrow-hover {
    transition: 150ms ease;
}

.menu-opener {
    height: 54px;
    margin-top: -1px;
    margin-bottom: -1px;
}

.menu-opener {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    min-width: 270px;
    max-width: 320px;
    height: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .4px;
    transition: all .25s ease;
}

.menu-opener .burger-menu {
    flex: 0 0 auto;
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
}

.burger-menu:before {
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: currentColor;
    transition: none;
}

.burger-menu i {
    margin-right: 10px;
}

.menu-opener .menu-label {
    flex: 1 1 auto;
    margin-right: 10px;
}

.menu-opener .arrow-hover {
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    transition: -webkit-transform .25s ease;
    transition: transform .25s ease;
    transition: transform .25s ease, -webkit-transform .25s ease;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.header-categories-nav-wrap {
    background-color: var(--primary-color);
}

.header-categories-nav {
    cursor: pointer;
}

.header-categories-nav.show-menu:hover .categories-menu-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    pointer-events: visible;
    pointer-events: unset;
    transition: 150ms ease;
}

.header-categories-nav.show-menu:hover .arrow-hover {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.home-badge {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 50%;
    transition: opacity .3s ease;
    top: 7px;
    left: 7px;
}

span.badge.badge-pill.badge-primary {
    min-width: 50px;
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    word-break: break-all;
    letter-spacing: .4px;
    font-weight: 600;
    line-height: 1.2;
}

span.badge.badge-pill.badge-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 7px;
    min-height: 52px;
    font-size: 13px;
}

/* timer */
.timer {
    color: #333;
    font-weight: 600;
}

.timer-label {
    font-size: 0.8rem !important;
    display: block;
}

.timer-sec {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid var(--border-color);
    background: #e6edf3;
}

.timer-label {
    display: block;
    font-size: 0.9rem;
}

#timer {
    font-size: 20px;
    color: #999;
    letter-spacing: -1px;
}

#timer span {
    font-size: 20px;
    color: #333;
    margin: 0 3px 0 15px;
}

#timer span:first-child {
    margin-left: 0;
}

/* list widget */

.socials li {
    list-style: none;
}

ul.socials {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 50px;
    right: 25px;
    z-index: 1;
}

.product-grid-item .product-title a {
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.product-add-btn {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
}

.product-grid-item {
    text-align: center;
}

.product-hover-base .product-wrapper {
    position: relative;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
}

.product-hover-base .content-product-imagin {
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: 0;
    left: -15px;
    visibility: hidden;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    opacity: 0;
    -webkit-transform: scale(.98);
    transform: scale(.98);
}

.product-hover-base .product-element-top {
    margin-bottom: 0;
}

.product-grid-item .product-element-top {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.product-hover-base .product-element-top {
    margin-bottom: 0;
}

.product-grid-item .product-image-link {
    display: block;
}

.product-grid-item .hover-img img,
.product-grid-item .product-image-link img {
    width: 100%;
}

.product-grid-item.product-swatched .hover-img {
    display: none;
}

.product-grid-item .hover-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    opacity: 0;
    transition: opacity .5s ease, -webkit-transform 2s cubic-bezier(0, 0, .44, 1.18);
    transition: opacity .5s ease, transform 2s cubic-bezier(0, 0, .44, 1.18);
    transition: opacity .5s ease, transform 2s cubic-bezier(0, 0, .44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, .44, 1.18);
}

.product-grid-item .hover-img>a {
    display: block;
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
}

.product-hover-base .swatches-on-grid {
    margin-bottom: 0;
}

.product-grid-item .swatches-on-grid {
    margin-bottom: 5px;
}

.swatches-select {
    display: inline-block;
    vertical-align: middle;
}

.product-hover-base .wd-compare-btn {
    flex: 1 1 auto;
    text-align: right;
}

.product-grid-item .product-element-top:after {
    position: absolute;
    top: 50%;
    left: 50%;
    visibility: hidden;
    margin-top: -16px;
    margin-left: -16px;
    opacity: 0;
    transition: opacity .1s ease, visibility .1s ease;
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid #BBB;
    border-left-color: #000;
    border-radius: 50%;
    vertical-align: middle;
}

.product-hover-base .product-information {
    position: relative;
    padding: 10px;
}

.product-grid-item .product-title {
    margin-bottom: 5px;
    font-size: 14px;
}

.product-title {
    display: block;
}

.product-hover-base .wrapp-product-price {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: flex-end;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
}

.product-grid-item .price {
    display: block;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.3;
}

.price .amount {
    font-size: inherit;
}

.product-hover-base .fade-in-block {
    position: absolute;
    top: calc(100% - 5px);
    right: 0;
    left: 0;
    visibility: hidden;
    padding: 0 10px 10px 10px;
    opacity: 0;
}

.more-desc {
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    max-height: 88px;
}

.inner {
    line-height: 22px;
}

.product-hover-base .product-bottom-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.product-hover-base.hover.state-hover .product-wrapper,
.product-hover-base.hover:hover .product-wrapper {
    z-index: 20;
    -webkit-transform: translateY(-5px) translateZ(0);
    transform: translateY(-5px) translateZ(0);
}

.product-hover-base.hover.state-hover .content-product-imagin,
.product-hover-base.hover:hover .content-product-imagin {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease, visibility 0s ease, -webkit-transform .3s ease;
    transition: opacity .3s ease, visibility 0s ease, transform .3s ease;
    transition: opacity .3s ease, visibility 0s ease, transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.product-hover-base.hover.state-hover .fade-in-block,
.product-hover-base.hover:hover .fade-in-block {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease, visibility 0s ease;
}

.product-grid-item .product-element-top:hover .hover-img {
    opacity: 1;
    -webkit-transform: scale(1.09);
    transform: scale(1.09);
}

h3.h3 {
    text-align: center;
    text-transform: capitalize;
    font-size: 32px;
}

/********************* product-box style-1 **********************/
.product-box {
    text-align: center;
    padding: 0 0 72px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-box .product-image {
    position: relative;
    transition: all 0.3s ease 0s;
}

.product-box .product-image a {
    display: block;
}

.product-box .product-image img {
    width: 100%;
    height: auto;
}

.product-box .pic-1 {
    opacity: 1;
    transition: all 0.3s ease-out 0s;
}

.product-box:hover .pic-1 {
    opacity: 1;
}

.product-box .pic-2 {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-out 0s;
}

.product-box:hover .pic-2 {
    opacity: 1;
}

.product-box .social {
    width: 150px;
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    top: 60%;
    left: 50%;
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.product-box:hover .social {
    opacity: 1;
    top: 50%;
}

.product-box .social li {
    display: inline-block;
}

.product-box .social li a {
    color: #fff;
    background-color: #333;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 0 2px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.product-box .social li a:hover {
    color: #fff;
    background-color: #ef5777;
}

.product-box .social li a:after,
.product-box .social li a:before {
    content: attr(data-tip);
    color: #fff;
    background-color: #000;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px;
}

.product-box .social li a:after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: -20px;
    z-index: -1;
}

.product-box .social li a:hover:after,
.product-box .social li a:hover:before {
    opacity: 1;
}

.product-box .product-discount-label,
.product-box .product-new-label {
    color: #fff;
    background-color: #ef5777;
    font-size: 12px;
    text-transform: uppercase;
    padding: 2px 7px;
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
}

.product-box .product-discount-label {
    background-color: #333;
    left: auto;
    right: 0;
}

.product-box .rating {
    color: #FFD200;
    font-size: 12px;
    padding: 12px 0 0;
    margin: 0;
    list-style: none;
    position: relative;
    z-index: -1;
}

.product-box .rating li.disable {
    color: rgba(0, 0, 0, 0.2);
}

.product-box .product-content {
    background-color: #fff;
    text-align: center;
    padding: 12px 0;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -27px;
    z-index: 1;
    transition: all 0.3s;
}

.product-box:hover .product-content {
    bottom: 0;
}

.product-box .title {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin: 0 0 10px;
    transition: all 0.3s ease 0s;
}

.product-box .title a {
    color: #828282;
}

.product-box .title a:hover {
    color: #ef5777;
}

.product-box:hover .title a {
    color: #ef5777;
}

.product-box .price {
    color: #333;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.6px;
    margin-bottom: 8px;
    text-align: center;
    transition: all 0.3s;
}

.product-box .price span {
    color: #999;
    font-size: 13px;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 3px;
    display: inline-block;
}

.product-box .add-to-cart {
    color: #000;
    font-size: 13px;
    font-weight: 600;
}

/********************* product-box style-2 **********************/
.demo {
    padding: 45px 0;
}

.product-box2 {
    position: relative;
}

.product-box2 .product-image2 {
    overflow: hidden;
    position: relative;
}

.product-box2 .product-image2 a {
    display: block;
}

.product-box2 .product-image2 img {
    width: 100%;
    height: auto;
}

.product-image2 .pic-1 {
    opacity: 1;
    transition: all 0.5s;
}

.product-box2:hover .product-image2 .pic-1 {
    opacity: 0;
}

.product-image2 .pic-2 {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
}

.product-box2:hover .product-image2 .pic-2 {
    opacity: 1;
}

.product-box2 .social {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 50px;
    right: 25px;
    z-index: 1;
}

.product-box2 .social li {
    margin: 0 0 10px;
    display: block;
    transform: translateX(100px);
    transition: all 0.5s;
}

.product-box2:hover .social li {
    transform: translateX(0);
}

.product-box2:hover .social li:nth-child(2) {
    transition-delay: 0.15s;
}

.product-box2:hover .social li:nth-child(3) {
    transition-delay: 0.25s;
}

.product-box2 .social li a {
    color: #505050;
    background-color: #fff;
    font-size: 17px;
    line-height: 45px;
    text-align: center;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: block;
    transition: all 0.3s ease 0s;
}

.product-box2 .social li a:hover {
    color: #fff;
    background-color: #3498db;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.product-box2 .social li a:after,
.product-box2 .social li a:before {
    content: attr(data-tip);
    color: #fff;
    background-color: #000000;
    font-size: 12px;
    line-height: 22px;
    border-radius: 3px;
    padding: 0 5px;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    top: 12px;
    right: 55px;
}

.product-box2 .social li a:after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: 16px;
    z-index: -1;
    right: 45px;
}

.product-box2 .social li a:hover:after,
.product-box2 .social li a:hover:before {
    opacity: 1;
}

.product-box2 .add-to-cart {
    color: #fff;
    background-color: #404040;
    font-size: 15px;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    display: block;
    position: absolute;
    left: 0;
    bottom: -100%;
    transition: all 0.3s;
}

.product-box2 .add-to-cart:hover {
    background-color: #3498db;
    text-decoration: none;
}

.add-to-cart:hover {
    color: #fff !important;
}

.product-box2:hover .add-to-cart {
    bottom: 0;
}

.product-box2 .product-new-label {
    background-color: #3498db;
    color: #fff;
    font-size: 17px;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s;
}

.product-box2:hover .product-new-label {
    opacity: 0;
}

.product-box2 .product-content {
    padding: 20px 10px;
    text-align: center;
}

.product-box2 .title {
    font-size: 17px;
    margin: 0 0 7px;
}

.product-box2 .title a {
    color: #303030;
}

.product-box2 .title a:hover {
    color: #3498db;
}

.product-box2 .price {
    color: #303030;
    font-size: 15px;
}

/********************* product-box style-3 **********************/
.product-box3 {
    text-align: center;
    position: relative;
    z-index: 1;
}

.product-box3:before {
    content: "";
    height: 81%;
    width: 100%;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s ease 0s;
}

.product-box3:hover:before {
    opacity: 1;
    height: 100%;
}

.product-box3 .product-image3 {
    position: relative;
}

.product-box3 .product-image3 a {
    display: block;
}

.product-box3 .product-image3 img {
    width: 100%;
    height: auto;
}

.product-box3 .pic-1 {
    opacity: 1;
    transition: all 0.5s ease-out 0s;
}

.product-box3:hover .pic-1 {
    opacity: 0;
}

.product-box3 .pic-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease-out 0s;
}

.product-box3:hover .pic-2 {
    opacity: 1;
}

.product-box3 .social {
    width: 120px;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -23px;
    transform: scale(0);
    transition: all 0.3s ease 0s;
}

.product-box3:hover .social {
    opacity: 1;
    transform: scale(1);
}

.product-box3:hover .product-discount-label,
.product-box3:hover .product-new-label,
.product-box3:hover .title {
    opacity: 0;
}

.product-box3 .social li {
    display: inline-block;
}

.product-box3 .social li a {
    color: var(--primary-color);
    background: #fff;
    font-size: 18px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin: 0 2px;
    display: block;
    transition: all 0.3s ease 0s;
}

.product-box3 .social li a:hover {
    background: var(--primary-color);
    color: var(--light-color);
}

.product-box3 .product-discount-label,
.product-box3 .product-new-label {
    background-color: var(--primary-color);
    color: #fff;
    font-size: 17px;
    padding: 2px 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all 0.3s;
}

.product-box3 .product-content {
    z-index: -1;
    padding: 15px;
    text-align: left;
}

.product-box3 .title {
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 0 7px;
    transition: all 0.3s ease 0s;
}

.product-box3 .title a {
    color: #414141;
}

.product-box3 .price {
    color: #000;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-right: 2px;
    display: inline-block;
}

.product-box3 .price span {
    color: #909090;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    text-decoration: line-through;
    text-align: left;
    display: inline-block;
    margin-top: -2px;
}

.product-box3 .rating {
    padding: 0;
    margin: -22px 0 0;
    list-style: none;
    text-align: right;
    display: block;
}

.product-box3 .rating li {
    color: #ffd200;
    font-size: 13px;
    display: inline-block;
}

.product-box3 .rating li.disable {
    color: #dcdcdc;
}

/********************* product-box style-4 **********************/
.product-box4 {
    position: relative;
    font-family: Poppins, sans-serif;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.product-box4 .product-image4 {
    position: relative;
}

.product-box4:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.product-box4 .product-image4 a {
    display: block;
}

.product-box4 .product-image4 img {
    width: 100%;
    height: auto;
}

.product-box4 .pic-1 {
    opacity: 1;
    transition: all 0.5s ease-out 0s;
}

.product-box4:hover .pic-1 {
    opacity: 0;
}

.product-box4 .pic-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease-out 0s;
}

.product-box4:hover .pic-2 {
    opacity: 1;
}

.product-box4 .social {
    width: 180px;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
}

.product-box4 .social li {
    display: inline-block;
    opacity: 0;
    transition: all 0.7s;
}

.product-box4 .social li:nth-child(1) {
    transition-delay: 0.15s;
}

.product-box4 .social li:nth-child(2) {
    transition-delay: 0.3s;
}

.product-box4 .social li:nth-child(3) {
    transition-delay: 0.45s;
}

.product-box4:hover .social li {
    opacity: 1;
}

.product-box4 .social li a {
    color: #222;
    background: #fff;
    font-size: 17px;
    line-height: 36px;
    width: 40px;
    height: 36px;
    border-radius: 2px;
    margin: 0 5px;
    display: block;
    transition: all 0.3s ease 0s;
}

.product-box4 .social li a:hover {
    color: #fff;
    background: var(--primary-color);
}

.product-box4 .social li a:after,
.product-box4 .social li a:before {
    content: attr(data-tip);
    color: #fff;
    background-color: #000;
    font-size: 12px;
    line-height: 20px;
    border-radius: 3px;
    padding: 0 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px;
}

.product-box4 .social li a:after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: -22px;
    z-index: -1;
}

.product-box4 .social li a:hover:after,
.product-box4 .social li a:hover:before {
    opacity: 1;
}

.product-box4 .product-discount-label,
.product-box4 .product-new-label {
    color: #fff;
    background-color: #16a085;
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 45px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 15px;
    transition: all 0.3s;
}

.product-box4 .product-discount-label {
    left: auto;
    right: 10px;
    background-color: #d7292a;
}

.product-box4:hover .product-new-label {
    opacity: 0;
}

.product-box4 .product-content {
    padding: 25px;
}

.product-box4 .title {
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 7px;
    transition: all 0.3s ease 0s;
}

.product-box4 .title a {
    color: #222;
}

.product-box4 .title a:hover {
    color: var(--primary-color);
}

.product-box4 .price {
    color: var(--primary-color);
    font-size: 17px;
    font-weight: 700;
    margin: 0 2px 15px 0;
    display: block;
}

.product-box4 .price span {
    color: #909090;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    text-decoration: line-through;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
}

.product-box4 .add-to-cart {
    border: 1px solid #e5e5e5;
    display: inline-block;
    padding: 10px 20px;
    color: #888;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.3s;
}

.product-box4:hover .add-to-cart {
    border: 1px solid transparent;
    background: #16a085;
    color: #fff;
}

.product-box4 .add-to-cart:hover {
    background-color: #505050;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/********************* product-box style-5 **********************/
.product-image5 .pic-1,
.product-image5 .pic-2 {
    backface-visibility: hidden;
    transition: all 0.5s ease 0s;
}

.product-box5 {
    font-family: Poppins, sans-serif;
    position: relative;
}

.product-box5 .product-image5 {
    overflow: hidden;
    position: relative;
}

.product-box5 .product-image5 a {
    display: block;
}

.product-box5 .product-image5 img {
    width: 100%;
    height: auto;
}

.product-image5 .pic-1 {
    opacity: 1;
}

.product-box5:hover .product-image5 .pic-1 {
    opacity: 0;
}

.product-image5 .pic-2 {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.product-box5:hover .product-image5 .pic-2 {
    opacity: 1;
}

.product-box5 .social {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 10px;
}

.product-box5 .social li {
    display: block;
    margin: 0 0 10px;
    transition: all 0.5s;
}

.product-box5 .social li:nth-child(2) {
    opacity: 0;
    transform: translateY(-50px);
}

.product-box5:hover .social li:nth-child(2) {
    opacity: 1;
    transform: translateY(0);
}

.product-box5 .social li:nth-child(3) {
    opacity: 0;
    transform: translateY(-50px);
}

.product-box5:hover .social li:nth-child(3) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
}

.product-box5 .social li a {
    color: #888;
    background: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: block;
    transition: 0.5s ease 0s;
}

.product-box5 .social li a:hover {
    color: #fff;
    background: #1e3799;
}

.product-box5 .select-options {
    color: #777;
    background-color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 5px;
    margin: 0 auto;
    opacity: 0;
    display: block;
    position: absolute;
    width: 92%;
    left: 0;
    bottom: -100px;
    right: 0;
    transition: 0.5s ease 0s;
}

.product-box5 .select-options:hover {
    color: #fff;
    background-color: #1e3799;
    text-decoration: none;
}

.product-box5:hover .select-options {
    opacity: 1;
    bottom: 10px;
}

.product-box5 .product-content {
    padding: 20px 10px;
}

.product-box5 .title {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
    transition: all 0.3s ease 0s;
}

.product-box5 .title a {
    color: #222;
}

.product-box5 .title a:hover {
    color: #1e3799;
}

.product-box5 .price {
    color: #222;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
}

/*********************product-box style-6 **********************/
.product-box6 {
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: all 0.5s ease 0s;
}

.product-box6 .product-image6 {
    overflow: hidden;
}

.product-box6:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.product-box6 .product-image6 a {
    display: block;
}

.product-box6 .product-image6 img {
    width: 100%;
    height: auto;
    transition: all 0.5s ease 0s;
}

.product-box6:hover .product-image6 img {
    transform: scale(1.1);
}

.product-box6 .product-content {
    padding: 12px 12px 15px;
    transition: all 0.5s ease 0s;
}

.product-box6:hover .product-content {
    opacity: 0;
}

.product-box6 .title {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
    transition: all 0.3s ease 0s;
}

.product-box6 .title a {
    color: #000;
}

.product-box6 .title a:hover {
    color: #2e86de;
}

.product-box6 .price {
    font-size: 18px;
    font-weight: 600;
    color: #2e86de;
}

.product-box6 .price span {
    color: #999;
    font-size: 15px;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 7px;
    display: inline-block;
}

.product-box6 .social {
    background-color: #fff;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    bottom: -50%;
    left: 50%;
    z-index: 1;
    transition: all 0.5s ease 0s;
}

.product-box6:hover .social {
    opacity: 1;
    bottom: 20px;
}

.product-box6 .social li {
    display: inline-block;
}

.product-box6 .social li a {
    color: #909090;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    height: 45px;
    width: 45px;
    margin: 0 7px;
    border: 1px solid #909090;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.product-box6 .social li a:hover {
    color: #fff;
    background-color: var(--primary-color);
    width: 80px;
}

.product-box6 .social li a:after,
.product-box6 .social li a:before {
    content: attr(data-tip);
    color: #fff;
    background-color: var(--primary-color);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px;
}

.product-box6 .social li a:after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: -20px;
    z-index: -1;
}

.product-box6 .social li a:hover:after,
.product-box6 .social li a:hover:before {
    opacity: 1;
}


/********************* product-box style-7 **********************/
.product-box7 {
    position: relative;
    z-index: 1;
}

.product-box7 .product-image7 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    perspective: 1500px;
    position: relative;
    transition: all 0.3s ease 0s;
}

.product-box7 .product-image7 a {
    display: block;
}

.product-box7 .product-image7 img {
    width: 100%;
    height: auto;
}

.product-box7 .pic-1 {
    opacity: 1;
    transition: all 0.5s ease-out 0s;
}

.product-box7 .pic-2 {
    opacity: 0;
    transform: rotateY(-90deg);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease-out 0s;
}

.product-box7:hover .pic-2 {
    opacity: 1;
    transform: rotateY(0);
}

.product-box7 .social {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 3px;
    left: -20%;
    z-index: 1;
    transition: all 0.5s ease 0s;
}

.product-box7:hover .social {
    left: 17px;
}

.product-box7 .social li a {
    color: #fff;
    background-color: #333;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 15px 0;
    border-radius: 50%;
    display: block;
    transition: all 0.5s ease-in-out;
}

.product-box7 .social li a:hover {
    color: #fff;
    background-color: var(--primary-color);
}

.product-box7 .product-new-label {
    color: #fff;
    background-color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
}

.product-box7 .product-content {
    text-align: center;
    padding: 20px 0 0;
}

.product-box7 .title {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
    transition: all 0.3s ease 0s;
}

.product-box7 .title a {
    color: #333;
}

.product-box7 .title a:hover {
    color: #78e08f;
}

.product-box7 .rating {
    color: #ffd200;
    font-size: 12px;
    padding: 0;
    margin: 0 0 10px;
    list-style: none;
}

.product-box7 .price {
    color: #333;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
    transition: all 0.3s;
}

.product-box7 .price span {
    color: #999;
    font-size: 14px;
    font-weight: 700;
    text-decoration: line-through;
    margin-left: 7px;
    display: inline-block;
}

/********************* product-box style-8 **********************/
.product-box8 {
    font-family: Poppins, sans-serif;
    position: relative;
    z-index: 1;
}

.product-box8 .product-image8 {
    border: 1px solid #e4e9ef;
    position: relative;
    transition: all 0.3s ease 0s;
}

.product-box8:hover .product-image8 {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.product-box8 .product-image8 a {
    display: block;
}

.product-box8 .product-image8 img {
    width: 100%;
    height: auto;
}

.product-box8 .pic-1 {
    opacity: 1;
    transition: all 0.5s ease-out 0s;
}

.product-box8:hover .pic-1 {
    opacity: 0;
}

.product-box8 .pic-2 {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease-out 0s;
}

.product-box8:hover .pic-2 {
    opacity: 1;
}

.product-box8 .social {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 13px;
    right: 13px;
    z-index: 1;
}

.product-box8 .social li {
    opacity: 0;
    transform: translateY(3px);
    transition: all 0.5s ease 0s;
}

.product-box8:hover .social li {
    margin: 0 0 10px;
    opacity: 1;
    transform: translateY(0);
}

.product-box8:hover .social li:nth-child(1) {
    transition-delay: 0.1s;
}

.product-box8:hover .social li:nth-child(2) {
    transition-delay: 0.2s;
}

.product-box8:hover .social li:nth-child(3) {
    transition-delay: 0.4s;
}

.product-box8 .social li a {
    color: grey;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    border: 1px solid grey;
    display: block;
    transition: all 0.5s ease-in-out;
}

.product-box8 .social li a:hover {
    color: #000;
    border-color: #000;
}

.product-box8 .product-discount-label {
    display: block;
    padding: 4px 15px 4px 30px;
    color: #fff;
    background-color: #0081c2;
    position: absolute;
    top: 10px;
    right: 0;
    -webkit-clip-path: polygon(34% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(34% 0, 100% 0, 100% 100%, 0 100%);
}

.product-box8 .product-content {
    padding: 20px 0 0;
}

.product-box8 .price {
    color: #000;
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 8px;
    text-align: left;
    transition: all 0.3s;
}

.product-box8 .price span {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    margin-left: 7px;
    display: inline-block;
}

.product-box8 .product-shipping {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    padding-left: 35px;
    margin: 0 0 15px;
    display: block;
    position: relative;
}

.product-box8 .product-shipping:before {
    content: '';
    height: 1px;
    width: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
}

.product-box8 .title {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 30px;
    transition: all 0.3s ease 0s;
}

.product-box8 .title a {
    color: #000;
}

.product-box8 .title a:hover {
    color: #0081c2;
}

.product-box8 .all-deals {
    display: block;
    color: #fff;
    background-color: #2e353b;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    padding: 22px 5px;
    transition: all 0.5s ease 0s;
}

.product-box8 .all-deals .icon {
    margin-left: 7px;
}

.product-box8 .all-deals:hover {
    background-color: #0081c2;
}


/********************* product-box style-9 **********************/
.product-box9 {
    position: relative;
    font-family: Poppins, sans-serif;
    z-index: 1;
}

.product-box9 .product-image9 {
    position: relative;
}

.product-box9 .product-image9 a {
    display: block;
}

.product-box9 .product-image9 img {
    width: 100%;
    height: auto;
}

.product-box9 .pic-1 {
    opacity: 1;
    transition: all 0.5s ease-out 0s;
}

.product-box9:hover .pic-1 {
    opacity: 0;
}

.product-box9 .pic-2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease-out 0s;
}

.product-box9:hover .pic-2 {
    opacity: 1;
}

.product-box9 .product-full-view {
    color: #505050;
    background-color: #fff;
    font-size: 16px;
    height: 45px;
    width: 45px;
    padding: 18px;
    border-radius: 100px 0 0;
    display: block;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease 0s;
}

.product-9 .product-full-view:hover {
    color: #c0392b;
}

.product-box9:hover .product-full-view {
    opacity: 1;
}

.product-box9 .product-content {
    padding: 12px 12px 0;
    overflow: hidden;
    position: relative;
}

.product-content .rating {
    padding: 0;
    margin: 0 0 7px;
    list-style: none;
}

.product-box9 .rating li {
    font-size: 12px;
    color: #ffd200;
    transition: all 0.3s ease 0s;
}

.product-box9 .rating li.disable {
    color: rgba(0, 0, 0, 0.2);
}

.product-box9 .title {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 3px;
    transition: all 0.3s ease 0s;
}

.product-box9 .title a {
    color: rgba(0, 0, 0, 0.5);
}

.product-box9 .title a:hover {
    color: var(--primary-color);
}

.product-box9 .price {
    color: #000;
    font-size: 17px;
    margin: 0;
    display: block;
    transition: all 0.5s ease 0s;
}

.product-box9:hover .price {
    opacity: 0;
}

.product-box9 .add-to-cart {
    display: block;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    opacity: 0;
    position: absolute;
    left: 10px;
    bottom: -20px;
    transition: all 0.5s ease 0s;
}

.product-box9:hover .add-to-cart {
    opacity: 1;
    bottom: 0;
}

/* breadcrumb */
.responsive-breadcrumb {
    position: relative;
}

.small-text-size {
    font-size: 1.5rem !important;
}

.small-pd {
    padding: 2rem 0 !important;
}

.colored-breadcrumb {
    background-color: #d4ecff !important;
}

.breadcrumb-item a {
    color: #333;
    font-size: 14px;
}

.breadcrumb-title-bar {
    position: relative;
    padding: 2rem 0;
    background-color: #f5f5f5;
    border-bottom: 1px solid #eee;
}

.breadcrumb-title-bar .container {
    position: relative;
}

.breadcrumb-title-bar h1 {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    color: #333;
    font-size: 35px;
    text-transform: uppercase;
}

/* login-form */
.label-name {
    grid-template-columns: 0fr 2fr !important;
    color: #000 !important;
    font-weight: 600 !important;
    letter-spacing: 0.1rem;
}

.login-form {
    margin-bottom: auto;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 6px;
}

/* faq's */
.home_faq_sec {
    background-color: #f8f9fa;
    padding: 50px 0;
}

.home_faq_btn {
    font-size: 16px;
    display: flex;
    align-content: center;
    align-items: center;
}

.home_faq h2 {
    font-size: 2rem !important;
}

.home_faq_btn i {
    font-size: 1.3rem;
    position: absolute;
    top: 22px;
    right: 1rem;
}

.faq_image img {
    max-width: 100%;
}

/* header varient */
/* header1 */
.topbar-text-color {
    background: var(--primary-color);
    color: #fff !important;
}

#header.header-varient .topbar a {
    color: #fff !important;
}

.header-morph .main-nav>ul>li>a {
    color: var(--primary-color) !important;
}

.header-morph {
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    padding: 1px;
    background-color: #fff !important;
}

/* header2 */
.searchbar-form:active,
.searchbar-form:focus {
    border-color: var(--border-color) !important;
}

.dropdown {
    border-radius: 0px !important;
    background-color: #fff !important;
    border-color: 2px !important;
    border-color: var(--border-color) !important;
    border-width: 2px !important;
    border-left: 0px !important;
}

.pl {
    margin-left: 25%;
}

.hed-style.topper-white .topbar a {
    color: #fff !important;
}

.serachbar-icon {
    border: 2px solid var(--border-color);
    color: #000 !important;
}

.serachbar-icon {
    background-color: #fff !important;
    color: #909090 !important;
}

.dropdown-menu.show.dp-menu {
    left: auto;
    border-radius: 0 !important;
}

.dp-color {
    color: #909090 !important;
}

/* header3 */
.drop-ul li a:hover {
    color: var(--primary-color) !important;
    transition: 150ms ease;
}

.drop-ul li a {
    color: #777 !important;
}


.swiper-container-client {
    overflow: hidden;
}

.dark-footer-margin {
    margin-bottom: 0 !important;
    background-color: var(--primary-color);
}

.category-swiper {
    height: auto !important;
}

/* header4 */
.icon-swiper .swiper-slide:last-child {
    border-right: none !important;
}

.category-image-container svg {
    max-width: 100%;
    max-height: 100%;
}

.color-category {
    color: var(--secondary-color) !important;
}

.nav-color {
    background-color: var(--secondary-color) !important;
}

.icon-dark-sec {
    background-color: #253237 !important;
}

.icon-swiper .swiper-slide {
    background: #253237 !important;
}

.category-image .cat-font-color span {
    font-size: 1rem;
}

.icon-dark-sec .cat-font-color {
    color: #fff !important;
}

.offer-image img {
    max-width: 100%;
    margin: auto;
    height: auto;
}

/* footer varient */
.freedel-sec {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

/* style1 */
.list-style {
    list-style: none;
    display: inline-block;
}

.img-height {
    max-height: 25px;
}

.footer {
    background: #151414;
    padding: 30px 0;
    position: relative;
    color: #adb5bd;
}

.footer .logo-footer {
    font-size: 22px;
}

.footer .logo-footer img {
    max-width: 100%;
}

.footer-text {
    color: #fff;
    font-size: 1.3rem;
    letter-spacing: 2px;
}

.relative-class {
    position: relative !important;
    padding-top: 200px;
}

.alignment {
    text-align: left;
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
}

.text-footer {
    color: #151414 !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.shape>.footerbar-svg {
    -webkit-transform: scale(2);
    transform: scale(2);
    width: 100%;
    height: auto;
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.footer-bar {
    border-top: 1px solid #f1f1f138;
}

.footerbar-svg {
    overflow: hidden;
    vertical-align: middle;
}

.p-title {
    color: #fff !important;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: var(--primary-color);
    padding: 13px 20px;
    border: 1px solid var(--primary-color);
    top: 0;
    cursor: pointer;
    transition: 150ms;
}

.dark-shape-footer li {
    cursor: pointer;
    line-height: 2.8;
}

.dark-shape-footer li:hover {
    color: var(--primary-color);
    transition: 150ms ease;
}

/* style2 */
.footer-varient {
    position: relative;
    background-image: url(../demo/footer/footer1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.bottom-wrapper {
    background-color: #161719 !important;
    padding: 9px 6px 6px 8px;
    margin-top: 60px;
}

.grocery-footer-text small {
    color: #fff !important;
}

.follow-icon {
    float: left;
}

.icon-hover {
    width: 39.2px;
    height: 39.2px;
    margin: 5px 10px 5px 0;
    background: rgba(255, 255, 255, .03);
    border: 1px solid rgba(255, 255, 255, .03);
    background-color: transparent !important;
}

.link-color {
    outline: none;
}

.desc-text {
    color: #fff;
    text-align: left;
}

.image-class {
    margin-top: 115px;
    max-width: 100%;
}


.footer-logo-class {
    max-width: 100%;
}

.border-wrapper {
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 1px;
    width: 80%;
    margin-left: 7rem;
}

.category {
    text-align: left;
}

.category-image .cat-font-color h4 {
    font-size: 20px;
}

.category li {
    list-style: none;
    line-height: 40px;
}

.cat-name,
.cat-no {
    color: #fff;
}

.cat-no {
    float: right;
    margin-right: 56px;
}

.footer-varient-title {
    color: #fff !important;
    font-size: 1.6rem;
    margin-top: 51px;
}

.footer-varient-product-wrap {
    display: flex;
    align-items: stretch;
    flex-direction: row;
}

.footer-varient-product-title {
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
    transition: 150ms ease;
}

.footer-varient-product-title {
    color: #fff !important;
}

.footer-varient-title {
    border-bottom: none !important;
}

.category {
    cursor: pointer;
}

.category .cat-name:hover {
    transition: 150ms ease;
}

.footer-logo-footer img {
    max-width: 100%;
    max-height: 100px;
}

/* style3 */
.footer-section-footer {
    background: #151414;
    position: relative;
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.single-cta i {
    color: var(--primary-color);
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: flow-root;
}

.cta-text h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text p {
    color: #757575;
    font-size: 16px;
}

.footer-content-footer {
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo-footer {
    margin-bottom: 30px;
}

.footer-logo-footre img {
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 15px;
    color: #7e7e7e;
    line-height: 28px;
}

.footer-social-icon h4 {
    color: #fff;
    display: block;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-social-icon i {
    height: 44px;
    width: 44px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg {
    background: #3B5998;
}

.twitter-bg {
    background: #55ACEE;
}

.google-bg {
    background: #DD4B39;
}

.footer-widget-heading h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h4::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: var(--primary-color);
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover {
    color: var(--primary-color);
}

.footer-widget ul li a {
    color: #878787;
    text-transform: capitalize;
    font-size: 15px;
}

button:focus {
    outline: none !important;
}

.copyright-area {
    background: #202020;
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
}

.copyright-text p a {
    color: var(--primary-color);
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    transition: 150ms;
}

.footer-menu li a {
    font-size: 16px;
    color: #878787;
}

/* newsletter */
.newsletter-modal h2 {
    color: #fff;
    font-size: 2rem;
}

.newsletter-modal p {
    color: #fff;
}

.modal-backdrop.in {
    opacity: 0.9;
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    @media(max-width: 768px) {
        min-height: calc(100vh - 20px);
    }
}

.newsletter-field {
    border: 2px solid rgba(255, 255, 255, .25) !important;
    background-color: transparent !important;
}


.modal-form {
    border-radius: 0 !important;
}

.modal-sen {
    font-weight: 200;
}

.modal-newsletter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -3px;
    margin-left: -3px;
}

.modal-title {
    font-size: 2rem !important;
}

.modal-header {
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
    border-bottom: none !important;
}

/* invoice */
.invoice-bottombar h2 {
    font-size: 1.4rem;
}

.text-end {
    text-align: end;
}

.invoice-shadow {
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.border-bottom {
    border-bottom: 1px solid var(--border-color);
}

.logo-invoice {
    font-weight: 700;
    font-size: 27px;
    letter-spacing: 1px;
}

.invoice-text {
    font-size: 1.3rem;
}

/* back-to-top */
.rounded-backtop {
    border-radius: 6px !important;
}

.back-to-top:hover {
    transform: rotate(45deg);
}

.ttb-i:hover {
    transform: rotate(-45deg);
}

.back-to-top {
    display: none;
    z-index: 99;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    display: none;
    color: #3c4858;
    background-color: var(--secondary-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
    box-shadow: 0 0 3px rgba(22, 28, 45, 0.15);
}

.client-slider-image {
    max-width: 100%;
    cursor: pointer;
}

/* my-account elements */
.notif-badge {
    float: right;
}

.delete-icon {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 28px;
    left: 25px;
    z-index: 1;
    color: #ff0000;
    font-size: 1.3rem;
    cursor: pointer;
}

.user-profile-image {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 250px;
    line-height: 250px;
    border-radius: 60%;
    overflow: hidden;
}

.notification-details {
    border-bottom: 1px solid var(--border-color);
}

.notif-text {
    color: #a8b1b9;
}

.notif-title {
    font-weight: bold;
}

.notif-image img {
    max-width: 100%;
    max-height: 100%;
}

.notif-image {
    width: 100px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    vertical-align: middle;
}

.my-account-tab {
    border: 2px solid var(--border-color);
}

.edit-icon {
    color: var(--primary-color);
    cursor: pointer;
    transition: 150ms ease;
}

.user-add {
    border-bottom: 2px solid var(--border-color);
}

.coffee-icon {
    color: #ffffff;
    background-color: var(--secondary-color);
    border: 1px solid;
    padding: 11px 10px 10px 11px;
    border-radius: 50%;
}

.clock-icon {
    color: #f8f8f9;
    background-color: #4d6d6d;
    border: 1px solid;
    padding: 11px 10px 10px 11px;
    border-radius: 50%;
}

.shirt-icon {
    color: #ffffff;
    background-color: #2b3c35;
    border: 1px solid;
    padding: 11px 10px 10px 11px;
    border-radius: 50%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--primary-color) !important;
}

.add-ruppe {
    float: right;
    bottom: auto;
    top: auto;
    margin-top: -101px;
}

.add-wallet {
    display: flex;
}

.wallet-details {
    border-bottom: 2px solid var(--border-color);
    margin-bottom: 12px;
    margin-top: 12px;
}

.wallet-price .fa.fa-plus {
    color: var(--primary-color);
}

.wallet-price .fa.fa-minus {
    color: #ff0000;
}

.wallet-price {
    float: right;
    margin-top: -42px;
}

.wallet-info {
    display: flex;
    flex-wrap: wrap;
}

.text-style .fa-arrow-circle-down {
    color: #d82222;
}

.text-style .fa-arrow-alt-circle-up {
    color: #145414;
}

.tran-bg {
    background: #f7f5f5;
    padding: 8px 1px 8px 10px;
}

.border-green {
    border-left: 4px solid #347136;
}

.text-style {
    font-size: 12px;
    font-weight: 700;
    color: #333 !important;
}

.failed-text {
    color: #d82222 !important;
}

.trans-img {
    max-height: 25px;
}

.border-red {
    border-left: 4px solid #ff0000;
}

.border-blue {
    border-left: 4px solid var(--primary-color) !important;
}

.border-yellow {
    border-left: 4px solid var(--secondary-color) !important;
}

.user-image {
    border-radius: 50%;
    height: 80px;
    line-height: 80px;
    width: 80px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}

.user-image img {
    max-width: 100%;
    max-height: 100%;
}

.user-detail {
    display: flex;
}

.nav-link:active {
    color: #fff;
}

.nav-link {
    color: #495057;
}

.ion-icon {
    font-size: 64px;
}

/* mobile illustrator */
.mobile-app-wrapper img {
    max-width: 100%;
}

.mobile-app h1 {
    color: #000 !important;
}

.mobile-app .header-p {
    color: #414040 !important;
}

.mobile-app {
    background: #ffffff !important;
}

.mobile-app-text {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2em;
}

/* pagination */
ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}

ul.pagination li a.active {
    background-color: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
}

ul.pagination li a:hover:not(.active) {
    background-color: #ddd;
}

/* end pagination */

/* blog */
.blog-post {
    margin: 12px;
}

.blog-grid-post {
    margin: auto;
}

.blog-title span,
.related-post span,
.comment-sec span {
    color: var(--primary-color);
}

.blog-post-inner-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
}

.blog h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #333;
}

.blog-title a {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
}

.blog-post-inner-image {
    max-width: 100%;
}

.blog-data i {
    position: relative;
    top: 1px;
    padding-left: 1px;
    margin-right: 5px;
}

.blog-data li {
    margin-right: 0.5rem;
}

.blog-desc {
    position: relative;
    margin-top: 30px;
}

.blog-desc p {
    line-height: 1.8;
    margin-bottom: 30px;
}

.blog-desc a {
    color: var(--primary-color);
    font-style: italic;
    font-weight: 600;
    border-bottom: 1px solid var(--primary-color);
}

.blog-desc .border-bottom {
    border-bottom: 1px solid #d1d1d1;
}

.grid-blog-image img {
    max-width: 100%;
}

.grid-blog-title h2 {
    font-size: 1rem;
}

.related-post h4,
.comment-sec h4,
.sidebar-category-widget h4,
.sidebar-tagcloud h4,
.recent-post-widget h4 {
    font-size: 1.5rem;
    font-weight: 700;
}

.comment-sec form {
    max-width: none !important;
}

.category-list li {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
}

.category-list li a {
    display: block;
    position: relative;
    padding: .8rem 0 .8rem 2rem;
    border-bottom: 1px solid #ededde;
    color: #000;
}

.category-list li a:hover {
    background-color: #eee;
    transition: 150ms ease;
}

.category-list li a:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    margin: 0.4rem 0 0 -2rem;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #333;
    content: '';
}

.sidebar-tagcloud a {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 3px 6px;
    border: 1px solid #e5e5e5;
    font-size: 13px !important;
    color: #666;
    border-radius: 2px;
}

.sidebar-tagcloud a:hover,
.sidebar-tagcloud.active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: 150ms ease;
}

.post-list .blog-media {
    width: 6.5rem;
    margin: 0 1.2rem 0 0;
    border-radius: 0;
    line-height: 0;
}

.post-list li {
    display: flex;
}

.post-list .blog-media img {
    display: block;
    width: 100%;
    max-width: none;
    height: auto;
}

.blog-info a {
    color: #000;
}

.blog-share {
    display: flex;
    border-bottom: 1px solid #dee2e6 !important;
    padding: 12px;
}

.blog-share span {
    font-size: 1.3rem;
    font-weight: 500;
    margin-right: 12px;
}

/* end blog */

/* element styling */
.element-wrapper {
    background-color: white;
    position: relative;
    box-shadow: 0 8px 24px -12px rgba(150, 160, 170, 0.4);
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.element-wrapper .is-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.element-wrapper .is-flex.flex-column {
    flex-direction: column;
}

.element-wrapper .card-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.element-wrapper .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.75rem;
}

.element-wrapper .input-container:last-child {
    margin-bottom: 0;
}

.filter-nav__list {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
}

.text-label {
    margin: 3px 12px !important;
}

.toggle-input {
    outline: 0;
    user-select: none;
    display: none;
    position: absolute;
    opacity: 0;
}

.toggle {
    box-shadow: 0px 2px 4px rgba(133, 139, 146, 0.1);
    box-sizing: border-box;
    position: relative;
    transition: all 150ms;
    display: flex;
    cursor: pointer;
}

.toggle+.toggle {
    margin-left: 0.75rem;
}

.toggle.switch {
    width: 48px;
    padding: 4px;
    background-color: #cfd1d1;
    border-radius: 12px;
}

.toggle.switch:hover {
    box-shadow: 0px 2px 4px rgba(133, 139, 146, 0.2);
}

.toggle.switch .toggle-inner {
    width: 16px;
    height: 16px;
    background-color: #fafafa;
    border-radius: 12px;
    transition: all 0.2s ease-in;
    box-shadow: 0px 1px 2px rgba(133, 139, 146, 0.1);
}

.toggle-input:indeterminate+.toggle.switch,
.toggle.switch.is-indeterminate {
    background-color: #26d0b9;
}

.toggle-input:indeterminate+.toggle.switch .toggle-inner,
.toggle.switch.is-indeterminate .toggle-inner {
    background-color: white;
    margin-left: 12px;
}

.toggle-input:checked+.toggle.switch .toggle-inner,
.toggle.switch.is-active .toggle-inner {
    margin-left: 24px;
    background-color: white;
}

.toggle-input:disabled+.toggle.switch,
.toggle.switch.is-disabled {
    pointer-events: none;
    background-color: rgba(240, 240, 240, 0.8);
}

.toggle-input:disabled+.toggle.switch .toggle-inner,
.toggle.switch.is-disabled .toggle-inner {
    background-color: rgba(255, 255, 255, 0.8);
}

.toggle.radio {
    width: 24px;
    height: 24px;
    padding: 4px;
    background-color: #f0f0f0;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.toggle.radio:hover {
    box-shadow: 0px 2px 4px rgba(133, 139, 146, 0.2);
}

.toggle.radio .toggle-inner {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #fafafa;
}

.toggle-input:checked+.toggle.radio,
.toggle.radio.is-active {
    background-color: var(--primary-color);
}

.toggle-input:checked+.toggle.radio .toggle-inner,
.toggle.radio.is-active .toggle-inner {
    background-color: white;
}

.toggle-input:disabled+.toggle.radio,
.toggle.radio.is-disabled {
    pointer-events: none;
    background-color: rgba(240, 240, 240, 0.8);
}

.toggle-input:disabled+.toggle.radio .toggle-inner,
.toggle.radio.is-disabled .toggle-inner {
    background-color: rgba(255, 255, 255, 0.8);
}

.toggle-input:disabled:checked+.toggle.radio,
.toggle.radio.is-active.is-disabled {
    pointer-events: none;
    background-color: rgba(38, 208, 185, 0.6);
}

.toggle-input:disabled:checked+.toggle.radio .toggle-inner,
.toggle.radio.is-active.is-disabled .toggle-inner {
    background-color: rgba(255, 255, 255, 0.8);
}

.toggle.checkbox {
    width: 24px;
    height: 24px;
    background-color: #cfd1d1;
    justify-content: center;
    align-items: center;
}

.toggle.checkbox:hover {
    box-shadow: 0px 2px 4px rgba(133, 139, 146, 0.2);
}

.toggle.checkbox .toggle-inner {
    width: 6px;
    height: 12px;
    border: solid #fafafa;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 1px;
    margin-bottom: 2px;
}

.toggle.checkbox.is-indeterminate {
    background-color: #26d0b9;
}

.toggle.checkbox.is-indeterminate .toggle-inner {
    width: 12px;
    height: 3px;
    transform: rotate(0);
    border: 0;
    margin-bottom: 0;
    background-color: #fafafa;
}

.toggle-input:checked+.toggle.checkbox .toggle-inner,
.toggle.checkbox.is-active .toggle-inner {
    border-color: white;
}

.toggle-input:disabled+.toggle.checkbox,
.toggle.checkbox.is-disabled {
    pointer-events: none;
    background-color: rgba(240, 240, 240, 0.8);
}

.toggle-input:disabled+.toggle.checkbox .toggle-inner,
.toggle.checkbox.is-disabled .toggle-inner {
    border-color: rgba(255, 255, 255, 0.8);
}

.toggle-input:disabled:checked+.toggle.checkbox,
.toggle.checkbox.is-active.is-disabled {
    pointer-events: none;
    background-color: rgba(38, 208, 185, 0.6);
}

.toggle-input:disabled:checked+.toggle.checkbox .toggle-inner,
.toggle.checkbox.is-active.is-disabled .toggle-inner {
    border-color: rgba(255, 255, 255, 0.8);
}

/* end */

/* cart */
.cart-product-title {
    display: table-cell;
    vertical-align: middle;
    margin-bottom: 0;
}

.cart-product-title a {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.cart-product-image {
    display: block;
    margin-bottom: 0;
    vertical-align: middle;
    height: 130px;
    width: 130px;
    line-height: 130px;
}

.cart-product-image img {
    max-width: 50%;
}

.cart-product-desc-list td {
    vertical-align: middle !important;
}

.table-cart-product thead th {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: none !important;
    font-weight: 600;
    font-size: 1rem;
}

.cart-product-desc-list td i {
    font-size: 20px;
    cursor: pointer;
}

.responsive {
    width: 100%;
}

.cart-total th {
    padding: 10px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left !important;
}

.cart-total td {
    color: #777;
    text-align: right !important;
    font-weight: 400;
    padding: 10px;
    line-height: 1.4;
}

.cart-total {
    border-bottom: 1px solid #dcdcdc;
}

.cart-product-summary h4 a:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: .4rem;
    transform: translateY(-50%);
    font-family: 'porto';
    font-size: 1.4rem;
}

.cart-product-summary {
    margin-bottom: 2.5rem;
    padding: 1.4rem 1.8rem 2.2rem;
    border: 1px solid #ddd;
    background: #fbfbfb;
    min-height: 296px;
}

.cart-product-summary h3 {
    margin-bottom: 1.3rem;
    font-size: 28px;
    font-weight: 300;
}

.cart-product-price h2 {
    border-top: 1px solid #ccc;
    font-size: 1.5rem;
    font-weight: 600;
}

.cart-total-price .table {
    margin-bottom: 2rem;
    border-top: 1px solid #ccc;
}

.cart-product-price a {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    font-weight: 600;
}

.cart-product-price i {
    margin-top: 5px;
    font-weight: 600;
}

.total-price td {
    font-size: 18px;
    font-weight: 600;
}

.cart-products-table tr th:last-child,
.cart-products-table tr td:last-child {
    text-align: right;
}

.cart-product-tax td {
    border-top: none !important;
}

.total-price td {
    color: var(--primary-color);
}

/* end */

/* checkout */
.ship-title-details {
    font-size: 20px;
}

.item-price {
    margin-left: auto;
}

.checkout-order-wrapper {
    border: 1px dashed #d7d7d7;
    background: #f5f5f5;
}

.product-checkout-title a {
    font-size: 20px;
    font-weight: 300;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0;
    position: relative;
}

.checkout-title h1,
.checkout-form-title,
.ship-title-details h2 {
    font-size: 20px;
    font-weight: 500;
    padding: 18px;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative;
}

.checkout-order-wrapper {
    padding: 22px 30px;
    margin: 0 0 20px;
}

/* end */

/* contact us */
.info-wrapper {
    min-height: 180px;
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0 2rem 2rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -.75rem;
    margin-right: -.75rem;
}

.info-icon {
    background-color: #fff;
    margin-top: -2rem;
    margin-bottom: 1.5rem;
    border: none !important;
    width: 5.5rem;
    height: 4rem;
    padding: 0 .75rem;
    text-align: center;
}

.info-icon a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.info-icon a i {
    font-size: 3rem;
    line-height: 1;
    border: none;
    background-color: transparent !important;
    color: var(--primary-color);
    border-radius: 0;
    font-style: normal;
    font-size: 2.50rem;
    line-height: 4rem;
    text-align: center;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.info-content h3 {
    font-size: 18px;
}

span.subtitle {
    display: block;
    color: #878585;
    font-weight: 500;
    font-size: 16px;
}

/* end */



.color-wrapper {
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.color-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    overflow-x: hidden;
}

.color-content li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.color-wrapper-inner {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
}

.color {
    position: relative;
    margin-right: 10px;
    font-size: 0;
    transition: opacity .25s ease;
}

.color span::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .1);
    color: #FFF;
    font-weight: 700;
    line-height: 1;
    opacity: 0;
    transition: opacity .2s ease;
    content: "\f107";
}

.color-name {
    position: relative;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
}

.color span {
    width: 25px;
    height: 25px;
    position: relative;
    display: block;
    border-radius: 50%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.count-color-product {
    padding-right: 5px;
    padding-left: 5px;
    min-width: 30px;
    height: 20px;
    border: 1px solid;
    border-radius: 35px;
    color: #777;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    transition: color .25s ease, background-color .25s ease, border-color .25s ease;
}

.card-header-custom {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    font-size: 24px;
}

.card-custom {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border: none !important;
    border-radius: .25rem;
}

.card-body-custom {
    padding: 0px 8px 0 33px !important;
}

.collapse-arrow i {
    font-size: 1.3rem;
    position: absolute;
    top: 26px;
    right: 1rem;
}

.collapse-arrow {
    width: 100% !important;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;
    white-space: pre-wrap;
}

.grid-view i {
    font-size: 1.5rem;
    margin: auto;
    width: 50%;
    padding: 10px;
    color: #000;
}

.filter-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
}

.breadcrumb-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-breadcrumb a:after {
    content: "/";
    margin-left: 5px;
}

.product-breadcrumb {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #333;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
}

.product-breadcrumb a {
    color: #8E8E8E;
    margin-right: 6px
}

.breadcrumb-last {
    margin-right: 0;
    font-weight: 600;
}

.product-page-amount {
    display: none;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}

.product-per-page {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 23px;
    color: #8E8E8E;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

span.page-per-title {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0;
    color: #2d2a2a;
    vertical-align: middle;
    font-weight: 600;
}

span.page-per-title:after {
    content: ' :';
}

a.page-variation {
    display: inline-block;
    padding: 0 7px;
    color: #8E8E8E;
    vertical-align: middle;
}

.product-view {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 30px;
    vertical-align: middle;
}

a.grid-view {
    margin-right: 12px;
    vertical-align: middle;
}

.product-filter-form {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 30px;
    vertical-align: middle;
}

.product-filter-form select {
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 20px;
    max-width: 200px;
    height: auto;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    background-position: right 0 top 50%;
    color: #2d2a2a;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.page-border {
    display: inline-block;
    vertical-align: middle;
}

.page-border:after {
    content: "/";
}

.page-border:last-child {
    display: none;
}

.current-page {
    color: #2d2a2a !important;
    font-weight: 600;
}

.product-sidebar {
    display: none;
    float: right;
    align-items: center;
    color: #2d2a2a;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
}

.product-sidebar .dropdown {
    max-width: 600px;
    margin: 0 auto;
    padding: 23px 0;
}

/* end */

/* product list view */

.list-product-title a {
    font-size: 18px !important;
}

.list-product-desc {
    font-size: .9rem !important;
}

/* end */

/* product details */
.product-nav-tab:hover {
    border-color: transparent !important;
}

.product-nav-tab.active {
    border-bottom: 2px solid var(--primary-color) !important;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
}

.review-title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -.015em;
    margin-bottom: 0;
}

.review-container {
    display: flex;
    display: -ms-flexbox;
    padding: 29px 0 8px;
}

.review-image {
    flex: 0 0 auto;
    padding: 0 22px 5px 8px;
}

.review-image img {
    max-width: 100%;
    border-radius: 50px;
}

.product-rating {
    line-height: 1;
    margin: 0 0 10px 1px;
    cursor: pointer;
    position: relative;
    display: inline-block;

}

.review-info {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: -.02em;
}

.reviewer-name {
    display: inline;
    font-family: inherit;
    font-size: 20px;
}

.add-review {
    background-color: #f4f4f4;
    padding: 3rem 2rem 3.5rem;
}

.review-date {
    font-size: 0.9rem;
}

.reviews .review-box, .reviews .review-box a {
    display: flex;
    width: 100px;
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    align-self: center;
    background: #e2e1e1;
    margin: auto;
    border: 1px solid white;
    border-radius: 2px;
}

.reviews img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.tag-title {
    margin: 0 0 0 1rem;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
}

.mob-product-slider img {
    max-width: 100%;
}

.product-nav-tab:active {
    background: transparent !important;
}

/* end */

/* errro */
.error-wrapper h2 {
    font-size: 3rem;
    font-weight: 600;
}

.error-wrapper h4 {
    font-size: 1.5rem;
    font-weight: 700;

}

/* end */
/* Make more adjustments for phone */

@media screen and (min-device-width: 300px) and (max-device-width: 800px) {
    .text-center.text-white.category-section-title h3 {
        font-size: 28px !important;
    }

    .featured-section-title h3 {
        font-size: 28px;
    }

    .home_faq_btn i {
        top: inherit !important;
    }

    .faq_image img {
        max-width: 100%;
    }

    .faq-btn-text {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 350px) {


    .category-section-title h3 {
        font-size: 28px;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }

    .swiper-container.swiper1 {
        max-height: 110px;
        background: transparent;
    }

    .swiper1 .swiper-button-next,
    .swiper1 .swiper-button-prev {
        display: none;
    }

    .banner-swiper .swiper-button-next,
    .banner-swiper .swiper-button-prev {
        display: none;
    }

    .swiper1 .swiper-slide>img {
        max-width: 100%;
        height: -webkit-fill-available;
        height: -moz-available;
        height: -ms-available;
        height: -o-available;
        height: fill-available;
    }

    .main-content {
        max-width: 250px;
        min-width: 100px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 100px;
    }

    .shopping-cart .product-removal {
        float: right;
    }

    .shopping-cart .product-line-price {
        float: right;
        clear: left;
        width: auto;
        margin-top: 10px;
    }

    .shopping-cart .product .product-line-price:before {
        content: 'Item Total: $';
    }

    .shopping-cart .totals .totals-item label {
        width: 60%;
    }

    .shopping-cart .totals .totals-item .totals-value {
        width: 40%;
    }

    .banner-section {
        height: 300px !important;
    }

    .section-title {
        font-size: 20px;
    }

    .featured-section-title .title-sm {
        font-size: 10px;
    }

    #quick-view {
        margin-left: 20px;
        margin-right: 20px;
    }

    .product-page-details {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rating-sm {
        font-size: 12px;
    }

    .banner-section .banner-swiper .swiper-slide>img {
        width: 100%;
        height: 100%;
    }

    .category-swiper {
        width: 100%;
        height: 150px;
    }

    .category-section .category-image-container {
        height: 80px;
    }

    .product-style-1 .col-6 {
        padding: 0px;
    }

    .product-style-1 .product-grid .add-to-cart {
        font-size: 0.7rem;
    }

    .product-style-1 .product-grid .rating-sm {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 450px) {


    .category-section.category-section-title h3 {
        font-size: 28px !important;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }

    .swiper-container.swiper1 {
        max-height: 110px;
        background: transparent;
    }

    .swiper1 .swiper-button-next,
    .swiper1 .swiper-button-prev {
        display: none;
    }

    .banner-swiper .swiper-button-next,
    .banner-swiper .swiper-button-prev {
        display: none;
    }

    .swiper1 .swiper-slide>img {
        max-width: 100%;
        max-height: -webkit-fill-available;
        max-height: -moz-available;
        max-height: -ms-available;
        max-height: -o-available;
        max-height: fill-available;
    }

    .main-content {
        max-width: 250px;
        min-width: 100px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 100px;
    }

    .sidebar-filter-sm {
        display: none;
    }

    .shopping-cart-sidebar-btn {
        display: 18px !important;
        font-size: 18px !important;
    }

    .brand-logo-link {
        max-width: 150px;
        max-height: 60px;
    }

    .footer-logo-footer img {
        max-height: 100px;
        max-width: 250px;
    }

    .totals .totals-item .totals-value {
        width: 40%;
    }

    .filter-bars {
        display: inline-block;
    }

    .filter-bars {
        line-height: 33px;
        width: 38px;
        padding: 9px 36px 9px 12px;
    }

    .sort-by {
        float: right;
    }

    .banner-section .banner-swiper {
        height: 300px !important;
    }

    .banner-section .banner-swiper .swiper-slide>img {
        width: 100%;
        height: 100%;
    }

    .section-title {
        font-size: 20px;
    }

    .featured-section-title .title-sm {
        font-size: 10px;
    }

    #quick-view {
        margin-left: 20px;
        margin-right: 20px;
    }

    .product-page-details {
        padding-right: 35px;
        padding-left: 35px;
    }

    .rating-sm {
        font-size: 12px;
    }

    .category-swiper {
        width: 100%;
        height: 150px;
    }

    .category-section .category-image-container {
        height: 80px;
    }

    .product-style-1 .col-6 {
        padding: 0px;
    }

    .product-style-1 .product-grid .add-to-cart {
        font-size: 0.6rem !important;
    }

    .product-style-1 .product-grid .rating-sm {
        font-size: 10px !important;
    }

    .call-to-action-section .header-h1 {
        font: 600 2rem/2.25rem "Fira Sans", sans-serif !important;
    }
}

/* products with progress bar */
.p-image {
    background: #fcfaf8 !important;
}

.p-grid {
    border: none !important;
}

.progress-bar {
    background-image: linear-gradient(to right top, #8aabe0, #759fdc, #5e94d9, #4288d5, var(--primary-color));
    border-radius: 2rem;
}

.product-description h5 {
    font-size: 1.25rem !important;
}

.column-inner img {
    max-width: 100%;
    max-height: 100%;
}

/* color-switcher */
.setting-icon {
    font-size: 1.7rem !important;
    margin-top: 6px;
}

#colors-switcher {
    background: none repeat scroll 0 0 #fff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    left: -189px;
    position: fixed;
    top: 25%;
    width: 189px;
    z-index: 9999;
    padding: 10px 5px
}

#colors-switcher .color-bottom {
    background: none repeat scroll 0 0 #fff
}

#colors-switcher .color-bottom a.settings {
    background: none repeat scroll 0 0 #fff;
    height: 41px;
    position: absolute;
    right: -40px;
    top: 0px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0
}

#colors-switcher ul.color-style {
    list-style: none outside none;
    overflow: hidden;
    padding: 0;
    border-radius: 0px;
}

.list-item-inline {
    display: inline-block;
}

#colors-switcher ul.color-style li.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0
}

#colors-switcher ul.color-style li a {
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin: 3px
}

#colors-switcher ul.color-style .peach {
    background-color: #FF6A65;
}

#colors-switcher ul.color-style .default {
    background-color: #2f55d4;
}

#colors-switcher ul.color-style .yellow {
    background-color: #ffd45b;
}

#colors-switcher ul.color-style .green {
    background-color: #43ae47;
}

#colors-switcher ul.color-style .purple {
    background-color: #7952B3;
}

#colors-switcher ul.color-style .red {
    background-color: rgba(219, 51, 64, 1);
}

#colors-switcher ul.color-style .dark-blue {
    background-color: rgb(30 64 113);
}

#colors-switcher ul.color-style .orange {
    background-color: #f93;
}

#colors-switcher ul.color-style .cyan-dark {
    background-color: rgb(0 201 167 / 87%);
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {


    .swiper-container.gallery-thumbs.swiper-container-initialized.swiper-container-horizontal.swiper-container-free-mode.swiper-container-thumbs {
        display: none;
    }

    .product-page-content .product-title {
        font-size: 16px !important;
        font-weight: 400;
    }

    .category-section.category-section-title h3 {
        font-size: 28px !important;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }

    .error-wrapper img {
        max-width: 100%;
    }

    .blog-share span {
        font-size: 1rem !important;
    }

    .pagination {
        margin: 20px !important;
    }

    ul.pagination li a {
        padding: 3px 10px !important;
    }

    .product-title p {
        font-size: 1rem !important;
    }

    .left-border {
        display: none;
    }

    .text-style {
        font-size: 9px !important;
    }

    .topbar-right ul.list-inline {
        margin-left: 63px;
    }

    .hide-sec {
        display: none;
    }

    iframe {
        max-width: 100% !important;
        max-height: 100% !important;
    }

    .text-area a img {
        max-width: 40%;
    }

    .header-categories-nav {
        display: none;
    }

    .pl {
        margin-left: auto !important;
    }

    .image-class {
        max-width: 70%;
        float: left;
    }

    .padding-left {
        padding-left: 0 !important;
    }

    .padding {
        padding-right: 0 !important;
    }

    .header-bottom.color-dark {
        display: none;
    }

    label {
        grid-template-columns: 1fr;
    }

    label input,
    label select {
        margin-top: 8px;
    }

    .swiper-container.swiper1 {
        max-height: 136px;
        background: transparent;
    }

    .swiper1 .swiper-slide>img {
        max-width: 100%;
        max-height: -webkit-fill-available;
        max-height: -moz-available;
        max-height: -ms-available;
        max-height: -o-available;
        max-height: fill-available;
    }

    .swiper1 .swiper-button-next,
    .swiper1 .swiper-button-prev {
        display: none;
    }

    .banner-swiper .swiper-button-next,
    .banner-swiper .swiper-button-prev {
        display: none;
    }


    .main-content {
        max-width: 300px;
        min-width: 200px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }


    .home-slider {
        max-width: fit-content;
        min-width: 350px;
    }

    .sidebar-filter-sm {
        display: none;
    }

    .listing-page .filter-nav {
        display: none;
    }

    .shopping-cart-sidebar-btn {
        display: block !important;
    }

    .brand-logo-link {
        max-width: 150px;
        max-height: 60px;
    }

    .footer-logo-footer img {
        max-height: 100px;
        max-width: 250px;
    }

    .filter-bars {
        display: inline-block;
    }

    .filter-bars {
        line-height: 33px;
        width: 38px;
        padding: 9px 36px 9px 12px;
    }

    .sort-by {
        float: right;
    }

    .banner-section .banner-swiper {
        max-height: 300px !important;
    }

    .section-title {
        font-size: 20px;
    }

    .featured-section-title .title-sm {
        font-size: 10px;
    }

    #quick-view {
        margin-left: 20px;
        margin-right: 20px;
    }

    .rating-sm {
        font-size: 12px;
    }

    .category-swiper {
        width: 100%;
        height: 150px;
    }

    .category-section .category-image-container {
        height: 120px;
    }

    .category-section .swiper-slide .category-image-container {
        height: 70px;
    }

    .category-section .category-grid {
        padding: 0 0 30px;
    }

    .cat-font-color h4 {
        font-size: 13px !important;
    }

    .product-style-1 .product-grid .add-to-cart {
        font-size: 0.7rem;
    }

    .product-style-1 .product-grid .rating-sm, .product-style-2 .product-grid .rating-sm, .product-style-default .product-grid .rating-sm {
        font-size: 10px !important;
    }

    .call-to-action-section .header-h1 {
        font: 600 2.5rem/4.25rem "Fira Sans", sans-serif;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 650px) {


    .category-section.category-section-title h3 {
        font-size: 28px !important;
    }

    .product-page-content .product-title {
        font-size: 16px !important;
        font-weight: 400;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }

    .main-content {
        max-width: 100%;
        min-width: 230px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 200px;
    }

    .shopping-cart {
        padding-top: 20px;
        border-top: 1px solid #eee;
    }

    .column-labels {
        display: none;
    }

    .shopping-cart .product-image {
        float: right !important;
        width: auto !important;
    }

    .shopping-cart .product-image img {
        margin: 0 0 10px 10px !important;
    }

    .shopping-cart .product-details {
        float: none !important;
        margin-bottom: 10px !important;
        width: auto !important;
    }

    .shopping-cart .product-price {
        clear: both !important;
        width: 70px !important;
    }

    .shopping-cart .product-quantity {
        width: 100px !important;
    }

    .shopping-cart .product-quantity input {
        margin-left: 20px !important;
    }

    .shopping-cart .product-quantity:before {
        content: 'x' !important;
    }

    .shopping-cart .product-removal {
        width: auto !important;
    }

    .shopping-cart .product-line-price {
        float: right !important;
        width: 70px !important;
    }

    .product-page-details {
        padding-right: 35px;
        padding-left: 35px;
    }

    .responsive-breadcrumb {
        text-align: center;

    }

    .responsive-breadcrumb .breadcrumb {
        position: initial !important;
        transform: translateY(0%);
        display: inline-flex;
    }

    .header-bottom, .pb-2, .filter-nav {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {


    .category-section.category-section-title h3 {
        font-size: 28px !important;
    }

    .product-page-content .product-title {
        font-size: 16px !important;
        font-weight: 400;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }

    .product-sidebar {
        display: inline-flex;
    }

    .breadcrumb-wrapper {
        justify-content: center;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(129, 129, 129, .2);
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
    }

    .call-to-action-section .iphone-slider {
        margin-right: auto;
        margin-bottom: 5px;
        margin-left: 0;
    }

    .call-to-action-section .text-area {
        margin-top: 6rem;
        margin-left: 2rem;
    }

    .responsive-breadcrumb {
        text-align: center;

    }

    .responsive-breadcrumb .breadcrumb {
        position: initial !important;
        transform: translateY(0%);
        display: inline-flex;
    }

    .header-bottom, .pb-2, .filter-nav {
        display: none;
    }
}

/* Extra small devices (phones, 990px and down) */

@media only screen and (max-width: 991px) {
    .product-box {
        margin-bottom: 30px;
    }

    .product-page-content .product-title {
        font-size: 16px !important;
        font-weight: 400;
    }

    .product-box3 {
        margin-bottom: 30px;
    }

    .product-box3 .rating {
        margin: -22px 0 0;
    }

    .product-box4 {
        margin-bottom: 30px;
    }

    .product-box5 {
        margin-bottom: 30px;
    }

    .product-box6 {
        margin-bottom: 30px;
    }

    .product-box7 {
        margin-bottom: 30px;
    }

    .product-box8 {
        margin-bottom: 30px;
    }

    .product-box2 {
        margin-bottom: 30px;
    }

    .product-box9 {
        margin-bottom: 30px;
    }

    .topbar-left {
        display: none;
    }

    .topbar-right {
        text-align: center !important;
    }

    .navbar-top-search-box {
        display: none;
    }

    .brand-logo-link {
        max-width: 150px;
        max-height: 60px;
    }

    .footer-logo-footer img {
        max-height: 100px;
        max-width: 250px;
    }

    .shopping-cart-sidebar-btn {
        display: block !important;
    }

    .product-preview-image-section-md {
        display: none;
    }

    .product-preview-image-section-sm {
        display: block;
    }

    .cd-morph-dropdown {
        display: none;
    }

    .filter-bars {
        display: inline-block;
    }

    .filter-bars {
        line-height: 33px;
        width: 38px;
        padding: 9px 36px 9px 12px;
    }

    .sort-by {
        float: right;
    }

    .header-bottom, .pb-2 {
        display: none !important;
    }

}

@media only screen and (min-width: 1000px) {
    .header-bottom-inner {
        height: 52px;
    }

    .cd-dp {
        text-align: left !important;
    }

    .main-content {
        max-width: 600px;
        min-width: 530px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 800px;
    }

    .sidebar-filter {
        display: block;
    }

    .filter-nav-sm {
        display: none;
    }

    .cd-morph-dropdown {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: var(--primary-color);
    }

    .cd-morph-dropdown::before {
        content: 'desktop';
    }

    .cd-morph-dropdown .nav-trigger {
        display: none;
    }

    .cd-morph-dropdown .main-nav {
        display: inline-block;
    }

    .cd-morph-dropdown .main-nav>ul>li {
        display: inline-block;
        float: left;
    }

    .cd-morph-dropdown .main-nav>ul>li>a {
        display: block;
        padding: 0 1.8em;
        line-height: 33px;
        color: #fff;
        font-size: 1.0rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
    }


    .cd-morph-dropdown .morph-dropdown-wrapper {
        /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
        display: block;
        top: 35px;
        /* overwrite mobile style */
        width: auto;
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        /* Force Hardware acceleration */
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
    }

    .cd-morph-dropdown.is-dropdown-visible .morph-dropdown-wrapper {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    .cd-morph-dropdown .dropdown-list {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform, width, height;
        -webkit-transition: visibility .3s;
        transition: visibility .3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    }

    .no-csstransitions .cd-morph-dropdown .dropdown-list {
        display: none;
    }

    .cd-morph-dropdown .dropdown-list>ul {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .cd-morph-dropdown.is-dropdown-visible .dropdown-list {
        visibility: visible;
        -webkit-transition: width .3s, height .3s, -webkit-transform .3s;
        transition: width .3s, height .3s, -webkit-transform .3s;
        transition: transform .3s, width .3s, height .3s;
        transition: transform .3s, width .3s, height .3s, -webkit-transform .3s;
    }

    .cd-morph-dropdown .dropdown {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        -webkit-transition: opacity .3s, visibility .3s;
        transition: opacity .3s, visibility .3s;
    }

    .cd-morph-dropdown .dropdown.active {
        opacity: 1;
        visibility: visible;
    }

    .cd-morph-dropdown .dropdown.move-left .content {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    .cd-morph-dropdown .dropdown.move-right .content {
        -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px);
    }

    .cd-morph-dropdown .label {
        /* hide the label on bigger devices */
        display: none;
    }

    .cd-morph-dropdown .content>ul::after {
        clear: both;
        content: "";
        display: block;
    }

    .cd-morph-dropdown .content>ul>li {
        width: 150px;
        float: left;
        margin-top: 10px;
        text-align: left;
    }

    .cd-morph-dropdown .content>ul>li:nth-of-type(2n) {
        margin-right: 0;
    }

    .cd-morph-dropdown .gallery .content {
        /* you need to set a width for the .content elements because they have a position absolute */
        width: 510px;
        padding-bottom: .8em;
    }

    .cd-morph-dropdown .gallery .content li {
        margin-bottom: 1.8em;
    }

    .cd-morph-dropdown .links .content>ul>li {
        margin-top: 0;
    }

    .cd-morph-dropdown .links .content,
    .cd-morph-dropdown .button .content {
        width: 390px;
    }

    .cd-morph-dropdown .links-list a {
        font-size: 1.6rem;
        margin-left: 0;
    }

    .cd-morph-dropdown .btn {
        display: block;
        width: 100%;
        height: 60px;
        margin: 1.5em 0 0;
        font-size: 1.8rem;
        text-align: center;
        color: #FFFFFF;
        line-height: 60px;
        background: #DB6356;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .cd-morph-dropdown .btn:hover {
        background: #1A1A1A;
        color: #FFFFFF;
    }

    .cd-morph-dropdown .content h2 {
        font-size: 1.8rem;
        text-transform: none;
        font-weight: normal;
        color: #1A1A1A;
        margin: 0 0 .6em;
    }

    .cd-morph-dropdown .bg-layer {
        /* morph dropdown background */
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
        background: #FFFFFF;
        opacity: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .cd-morph-dropdown.is-dropdown-visible .bg-layer {
        opacity: 1;
        -webkit-transition: opacity .3s, -webkit-transform .3s;
        transition: opacity .3s, -webkit-transform .3s;
        transition: transform .3s, opacity .3s;
        transition: transform .3s, opacity .3s, -webkit-transform .3s;
    }

}



/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .main-content {
        max-width: 878px;
        min-width: 530px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 500px;
    }

    .call-to-action-section .text-area {
        margin-top: 6rem;
        margin-left: 2rem;
    }

    .call-to-action-section .header-h1 {
        font: 600 3.5rem/4.25rem "Fira Sans", sans-serif;
    }

    .call-to-action-section .header-p {
        font: 300 1.3em "Fira Sans", sans-serif;
    }

    .cd-morph-dropdown {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: var(--primary-color);
    }

    .cd-morph-dropdown::before {
        content: 'desktop';
    }

    .cd-morph-dropdown .nav-trigger {
        display: none;
    }


    .cd-morph-dropdown .main-nav {
        display: inline-block;
    }

    .cd-morph-dropdown .main-nav>ul>li {
        display: inline-block;
        float: left;
    }

    .cd-morph-dropdown .main-nav>ul>li>a {
        display: block;
        padding: 0 1.4vw;
        line-height: 33px;
        color: #fff;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
    }

    .cd-morph-dropdown .morph-dropdown-wrapper {
        /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
        display: block;
        top: 35px;
        /* overwrite mobile style */
        width: auto;
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        /* Force Hardware acceleration */
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
    }

    .cd-morph-dropdown.is-dropdown-visible .morph-dropdown-wrapper {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    .cd-morph-dropdown .dropdown-list {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform, width, height;
        -webkit-transition: visibility .3s;
        transition: visibility .3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    }

    .no-csstransitions .cd-morph-dropdown .dropdown-list {
        display: none;
    }

    .cd-morph-dropdown .dropdown-list>ul {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .cd-morph-dropdown.is-dropdown-visible .dropdown-list {
        visibility: visible;
        -webkit-transition: width .3s, height .3s, -webkit-transform .3s;
        transition: width .3s, height .3s, -webkit-transform .3s;
        transition: transform .3s, width .3s, height .3s;
        transition: transform .3s, width .3s, height .3s, -webkit-transform .3s;
    }

    .cd-morph-dropdown .dropdown {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        -webkit-transition: opacity .3s, visibility .3s;
        transition: opacity .3s, visibility .3s;
    }

    .cd-morph-dropdown .dropdown.active {
        opacity: 1;
        visibility: visible;
    }

    .cd-morph-dropdown .dropdown.move-left .content {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    .cd-morph-dropdown .dropdown.move-right .content {
        -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px);
    }

    .cd-morph-dropdown .label {
        /* hide the label on bigger devices */
        display: none;
    }

    .cd-morph-dropdown .content>ul::after {
        clear: both;
        content: "";
        display: block;
    }

    .cd-morph-dropdown .content>ul>li {
        width: 150px;
        float: left;
        margin-top: 10px;
        text-align: left;
    }

    .cd-morph-dropdown .content>ul>li:nth-of-type(2n) {
        margin-right: 0;
    }

    .cd-morph-dropdown .gallery .content {
        /* you need to set a width for the .content elements because they have a position absolute */
        width: 510px;
        padding-bottom: .8em;
    }

    .cd-morph-dropdown .gallery .content li {
        margin-bottom: 1.8em;
    }

    .cd-morph-dropdown .links .content>ul>li {
        margin-top: 0;
    }

    .cd-morph-dropdown .links .content,
    .cd-morph-dropdown .button .content {
        width: 390px;
    }

    .cd-morph-dropdown .links-list a {
        font-size: 1.6rem;
        margin-left: 0;
    }

    .cd-morph-dropdown .btn {
        display: block;
        width: 100%;
        height: 60px;
        margin: 1.5em 0 0;
        font-size: 1.8rem;
        text-align: center;
        color: #FFFFFF;
        line-height: 60px;
        background: #DB6356;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .cd-morph-dropdown .btn:hover {
        background: #1A1A1A;
        color: #FFFFFF;
    }

    .cd-morph-dropdown .content h2 {
        font-size: 1.8rem;
        text-transform: none;
        font-weight: normal;
        color: #1A1A1A;
        margin: 0 0 .6em;
    }

    .cd-morph-dropdown .bg-layer {
        /* morph dropdown background */
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
        background: #FFFFFF;
        opacity: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .cd-morph-dropdown.is-dropdown-visible .bg-layer {
        opacity: 1;
        -webkit-transition: opacity .3s, -webkit-transform .3s;
        transition: opacity .3s, -webkit-transform .3s;
        transition: transform .3s, opacity .3s;
        transition: transform .3s, opacity .3s, -webkit-transform .3s;
    }

    .product-grid {
        padding: 0px 0 130px !important;
    }

}

@media (max-width: 425px) {


    .product-box3 .rating {
        margin: 0;
    }

    .category-section.category-section-title h3 {
        font-size: 28px !important;
    }

    .featured-section-title h3 {
        font-size: 28px !important;
    }
}

/* @media(max-width: 1369px) { */
.svg-icon-image {
    max-width: 42px;
}

@media(min-width: 1920px) {
    .svg-icon-image {
        max-width: 62px;
    }
}

@media(max-width: 1024px) {


    .product-per-page,
    .product-view {
        display: none;
    }

    .product-tool-wrapper {
        width: 100%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .medium-slider-container.container {
        margin-left: 359px;
    }

    .medium-slider-container {
        max-width: 914px !important;
    }

    .vc_col-lg-3 {
        width: 25%
    }

    .product-box3 .rating {
        margin: 0;
    }

    .main-content {
        max-width: 1200px;
        min-width: 630px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: fit-content;
        min-width: 600px;
    }

    .call-to-action-section .text-area {
        margin-top: 8rem;
        margin-left: 0;
    }

    .call-to-action-section .iphone-slider {
        margin-left: 1.5rem;
    }

    .call-to-action-section .header-h1 {
        font: 600 3rem/4.75rem "Fira Sans", sans-serif;
    }

    .call-to-action-section .header-h1>span {
        font: 600 2rem/4.75rem "Fira Sans", sans-serif;
    }

    .call-to-action-section .apple-store {
        margin-bottom: 0rem;
    }

    .call-to-action-section .google-store {
        margin-bottom: 0rem;
    }

    .product-grid {
        padding: 0px 0 104px !important;
    }
}

@media only screen and (min-width: 1920px) {
    .medium-home-slider {
        min-width: 1390px !important;
    }

    .medium-slider-container.container {
        margin-left: 387px !important;
    }

    .text-area a img {
        max-width: 20%;
    }

    .padding-left {
        padding-left: 14rem !important;
    }

    .main-content {
        max-width: 1680px;
        min-width: 1500px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }

    .home-slider {
        max-width: 1680px;
        min-width: 1500px;
        margin: 12px auto 12px;
        padding: 0 16px;
    }
}

.center-swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 60%;
}

#modal-custom .iziModal-content header a.active {
    background: #0E7DD1;
    color: #fff;
}

#modal-custom .iziModal-content header a {
    background: #fff;
    color: #777;
}

.send-otp-form .form-input {
    padding: 15px 0 15px 60px !important;
}

.intl-tel-input {
    display: table-cell;
    width: 100%;
}

.add-favorite {
    background-color: #fff !important;
    z-index: 2;
    top: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    border-bottom-left-radius: 58px 58px;
}

.add-favorite button {
    background-color: #ffffff00;
}

.grid-view.active {
    background: #e4e4e4;
    border-radius: 13px;
}

#step2, #step3 {
    text-align: center;
}

#step4, #step5 {
    text-align: right;
}

#progressbar li.cancel {
    color: red !important;
}

.orders-section #progressbar li.cancel:after {
    width: 0;
}

.mobile-categories ul li a {
    padding: 2px 25px !important;
}

.payment-gateway-images {
    width: 30px;
}

.categgory-bg {
    background: #253237;
}

.text-light {
    color: #7e7e7e !important;
}



/*dark mode styling*/
.darkmode-toggle {
    z-index: 999;
    padding: 10px;
}

.darkmode-toggle {
    content: url(../images/moon.svg);

}

.darkmode-toggle--white {
    content: url(../images/sun.svg);
}


.darkmode--activated #header.topper-white, .darkmode--activated .categgory-bg, .darkmode--activated .center-swiper-slide, .darkmode--activated .footer-section-footer, .darkmode--activated .sidebar #leftside-navigation ul ul li {
    background: #131313;
}

.darkmode--activated .bg-white {
    background-color: #131313 !important;
}

.darkmode--activated .dropdown, .darkmode--activated .dropdown-menu, .darkmode--activated .mt-4 {
    background-color: #131313 !important;
}

.darkmode--activated .text-dark, .darkmode--activated p, .darkmode--activated .morph-text a, .darkmode--activated a, .darkmode--activated .featured-section-title, .darkmode--activated .text-label, .darkmode--activated .w-25, .darkmode--activated label, .darkmode--activated h4, .darkmode--activated .h3, .darkmode--activated .social, .darkmode--activated .mt-2 {
    color: #ffffff !important;
}

.darkmode--activated .card-body, .darkmode--activated .text-muted, .darkmode--activated .mobile-app .header-p, .darkmode--activated .breadcrumb-item.active {
    color: #b7b7b7 !important;
}

.darkmode--activated .cd-dp, .darkmode--activated .vertical-nav>li, .darkmode--activated .vertical-nav>a, .darkmode--activated .home-slider, .darkmode--activated .page-link {
    background-color: #131313 !important;
}

.darkmode--activated .category-section {
    background-color: #16151500
}

.darkmode--activated .icon-swiper, .darkmode--activated .swiper-slide, .darkmode--activated .product-preview-image-section-md, .darkmode--activated .num-in, .darkmode--activated .in-num {
    background: #131313 !important;
}

.darkmode--activated .banner-swiper .swiper-button-next, .darkmode--activated .banner-swiper .swiper-button-prev {
    background: #565555 !important;
}

.darkmode--activated .copyright-area {
    background: #0e0e0e;
}

.darkmode--activated .filter-section, .darkmode--activated .card, .darkmode--activated .product-page-details, .darkmode--activated .mt-3, .darkmode--activated .listing-page .menu, .darkmode--activated .listing-page .filter-nav.open {
    background-color: #131313;
}

.darkmode--activated .product-grid .product-content, .darkmode--activated .info-icon, .darkmode--activated .card-body, .darkmode--activated .dropdown-item:hover, .darkmode--activated .input-group>.input-group-prepend>.input-group-text, .darkmode--activated .sidenav, .darkmode--activated .sidebar {
    background-color: #191919;
}

.darkmode--activated .product-image-swiper-next, .darkmode--activated .product-image-swiper-prev {
    background: #131313 !important;
    color: #ffffff !important;
}

.darkmode--activated .toggle.checkbox {
    background-color: #2f2f2f;
}

.darkmode--activated .grid-view i, .darkmode--activated .product-title, .darkmode--activated .review-title, .darkmode--activated .reviewer-name, .darkmode--activated .breadcrumb-title-bar, .darkmode--activated h1, .darkmode--activated .pb-5, .darkmode--activated .py-5, .darkmode--activated .mb-0, .my-0, .darkmode--activated .align-self-center, .darkmode--activated .w-100, .darkmode--activated .product-details, .darkmode--activated h3, .darkmode--activated .checkout-form-title, .darkmode--activated .pb-3, .darkmode--activated h5, .darkmode--activated .product-info, .darkmode--activated .sidenav .close-sidenav a, .darkmode--activated .h1 {
    color: #fff !important;
}

.darkmode--activated .grid-view.active {
    background: #2d2d2d;
}

.darkmode--activated .add-favorite, .darkmode--activated .dr-item {
    background-color: #191919 !important;
}

.darkmode--activated .dr-item:hover {
    background-color: #313131 !important;
}

.darkmode--activated .select-box-dark {
    background-color: #131313;
    border: 1px solid var(--border-color) !important;
    color: #ffffff;
}

.darkmode--activated .login-form, .darkmode--activated .info-wrapper {
    border: 1px solid #e8e8e817;
    background-color: #131313;
}

.darkmode--activated .text-box {
    border: 2px solid #2d2d2d !important;
    color: #ffffff;
    background-color: #191919 !important;
}

.darkmode--activated .text-box:hover {
    border: 2px solid #ffffff !important;
    background-color: #191919 !important;
}

.darkmode--activated .swiper-button-next.swiper-button-black, .darkmode--activated .swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #ffffff;
}

.darkmode--activated .nav-link.active {
    background-color: #e0e0e0;
    border-color: #dee2e6 #dee2e6 #fff;
}

.darkmode--activated .bg-dark-bg {
    background-color: #9c9c9c;
}

.darkmode--activated .navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.darkmode--activated .form-control, .darkmode--activated .form-input {
    border: 2px solid #2d2d2d !important;
    color: #ffffff !important;
    background-color: #191919 !important;
}

.darkmode--activated .form-control:hover, .darkmode--activated .form-input:hover {
    border: 2px solid #ffffff !important;
    background-color: #191919 !important;
}

.darkmode--activated .h2 {
    color: #000 !important;
}

.darkmode--activated .h4 {
    color: #fff !important;
}

.darkmode--activated .icon-dark-sec {
    background-color: #e6e6e6 !important;
}

.darkmode--activated .mt-auto, .my-auto {
    color: #828b97;
}

.darkmode--activated .product-page-details label.btn.btn-default {
    border: 2px solid #ffffff36;
}

.darkmode--activated .num-in {
    background: #131313;
    border: 2px solid #ffffff36;
    ;
}

.darkmode--activated .add-review {
    background-color: #191919;
    border: 2px solid #ffffff36;
}

.darkmode--activated .cart-product-title {
    color: #a9a9a9;
}

.darkmode--activated .cart-product-summary {
    border: 1px solid #272727;
    background: #131313;
}

.darkmode--activated .iziModal .iziModal-wrap {
    background-color: #424242;
}

.darkmode--activated .checkout-order-wrapper {
    border: 1px dashed #464646;
    background: #131313;
}

.darkmode--activated .block {
    margin-top: 1.5rem !important;
}

.darkmode--activated .listing-page .menu__line {
    filter: brightness(0) invert(1);
}

.darkmode--activated .iziModal {
    background: initial;
}

.darkmode--activated #modal-custom .iziModal-content header a.active {
    background: #424242;
}

.darkmode--activated #modal-custom .iziModal-content header a {
    background: #000000;
}

.page-item.active .page-link {
    background-color: #007bff !important;
}

.darkmode--activated .select2-container--open .select2-dropdown--below {
    background-color: #191919;
}

.darkmode--activated .select2-result-repository__title {
    color: #b7b7b7;
}

.darkmode--activated .select2-results__option--highlighted .select2-result-repository__title {
    color: #fff;
}

.darkmode--activated .select2-search--dropdown .select2-search__field {
    background-color: #131313;
    color: #fff;
}

.darkmode--activated .select2-results__option--highlighted .select2-result-repository__description {
    color: #fff;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.limit_position {
    padding: 25px 33px 2em;
}

.category-line-height {
    margin: auto 10px;
}

.darkmode--activated .swal2-popup.swal2-toast {
    background: #000;
}

font {
    background-color: #28a745;
    padding-left: 4px;
    padding-right: 4px;
}

.darkmode--activated .hrDiv {
    color: #000000 !important
}

.hrDiv {
    font-size: 16px;
}

.swatch-img {
    max-width: 17%;
    height: auto;
}


.swatche-image {
    height: 39px;
    width: 43px;
    border-radius: 7%;
    display: inline-block;
}

.fullCircle {
    width: 39px;
    height: 37px;
    bordeR: 0px solid;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.buy-now-btn {
    position: fixed;
    left: 32px;
    bottom: 32px;
    z-index: 1;
}

.buy-now-btn .btn {
    border-radius: 30px;
    box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
}

.profile-image {
    max-width: 160px;
    height: 175px;
    margin-left: 22px;
    position: relative;
    vertical-align: baseline;
    line-height: 187px;
}

.card {
    border: none;
    border-radius: 15px;
    padding: 8px;
    background-color: #fff;
    position: relative;
}

.upper {
    height: 64px
}

.upper img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
}

.user {
    position: relative
}

.profile img {
    height: 80px;
    width: 80px;
    margin-top: 2px
}

.profile {
    position: absolute;
    top: -50px;
    left: 41%;
    height: 90px;
    width: 90px;
    border: 3px solid #fff;
    border-radius: 50%
}

.follow {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px
}

.stats span {
    font-size: 29px
}

.darkmode--activated .add-fav>button, .darkmode--activated .add-favorite button, .add-favorite, .darkmode--activated .add-fav {
    color: #a2a2a2;
    background: #191919;
}

.swal2-container {
    z-index: 9999;
}


@media screen and (min-width:750px) and (max-width:1100px) {
    .codrops-header {
        padding: 3em 10% 4em;
    }

    .faq_image img {
        max-width: 100%;
    }
}

.ZoomContainer {
    display: none;
}

.description, .description p, .description ul, .description ol, .description li, .description h1, .description h2, .description h3, .description span, .description div, .description table, .description td, .description th, .description tr {
    width: 100% !important;
}

/* whenever the window is resized, ask to refresh the page */
.refresh-page {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    background: #000000f0;
    z-index: 9999;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/* comparison product */

table {
    margin-bottom: 35px;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    line-height: 1.4
}

table th {
    padding: 15px 10px;
    border-bottom: 2px solid var(--brdcolor-gray-200);
    text-transform: uppercase;
    font-size: 16px;
}

table td {
    padding: 15px 12px;
    border-bottom: 1px solid var(--brdcolor-gray-300)
}

table tbody th,
table tfoot th {
    border-bottom: 1px solid var(--brdcolor-gray-300);
    text-transform: none;
    font-size: inherit
}

.responsive-table {
    overflow-x: auto;
    margin-bottom: 35px
}

.responsive-table table {
    margin-bottom: 0
}

.responsive-table .responsive-table {
    overflow-x: visible;
    margin-bottom: 0
}

.compare-table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0;
    line-height: inherit
}

.compare-table tbody {
    display: block
}

.compare-table tr {
    display: flex
}

.compare-table th,
.compare-table td {
    padding: 8px
}

.compare-table th:not(:last-child),
.compare-table td:not(:last-child) {
    border-inline-end: 1px solid rgb(0 0 0 / 15%);
}

.compare-table th {
    flex: 0 0 20%;
    text-transform: uppercase;
    color: rgb(0 0 0 / 100%);
}

.compare-table td {
    flex: 0 1 26.6666%;
    color: rgb(0 0 0 / 100%);
}

.compare-table td>* {
    margin-bottom: 10px;
    color: rgb(0 0 0 / 100%);
}

.compare-table td>*:last-child {
    margin-bottom: 0;
    color: rgb(0 0 0 / 100%);
}

.compare-table td:before {
    content: attr(data-title);
    display: none;
    margin-bottom: 15px;
    text-transform: uppercase
}

.compare-table .compare-basic td {
    padding-top: 0
}

.compare-table .compare-basic td:before {
    display: none
}

.compare-table .compare-brand img {
    max-width: 110px
}

@media (max-width: 1024px) {
    .compare-table th {
        flex: 0 0 33.33333%
    }

    .compare-table td {
        flex: 1 0 33.33333%
    }
}

@media (max-width: 768px) {
    .compare-table th {
        display: none
    }

    .compare-table td {
        flex-basis: 75%
    }

    .compare-table td:before {
        display: block
    }
}

#compare_count {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 3px 5px;
    bottom: 19px;
    margin-left: -6px;
    position: relative;
}